export interface NodeRel {
  text: string;
  href: string;
}

export default function MushafNav({
  nextNode,
  prevNode,
  parentNode,
}: {
  nextNode: NodeRel | null;
  prevNode: NodeRel | null;
  parentNode: NodeRel | null;
}) {
  if (!nextNode && !prevNode && !parentNode) {
    return null;
  }

  return (
    <nav className="mushaf-nav">
      {prevNode ? (
        <a
          className="mushaf-nav__prev"
          href={prevNode.href}
        >
          {prevNode.text}
        </a>
      ) : (
        <span className="mushaf-nav__prev" />
      )}

      {parentNode ? (
        <a
          className="mushaf-nav__up"
          href={parentNode.href}
        >
          {parentNode.text}
        </a>
      ) : (
        <span className="mushaf-nav__up" />
      )}
      {nextNode ? (
        <a
          className="mushaf-nav__next"
          href={nextNode.href}
        >
          {nextNode.text}
        </a>
      ) : (
        <span className="mushaf-nav__next" />
      )}
    </nav>
  );
}
