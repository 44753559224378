import "./index.css";

import * as E from "@muddakir/engine";
import { claim, migrate, safeImportGraph } from "@muddakir/graph";
import { load, Unit } from "@muddakir/quran-db";
import { MultiDirectedGraph } from "graphology";
import type { AbstractGraph, SerializedGraph } from "graphology-types";
import { createRoot } from "react-dom/client";
import migrations from "../db/migrate";
import GoTo from "./go-to";
import { GraphHistory } from "./graph-history";
import { FSPersistence, IDBPersistence } from "./persistence";
import { SearchIndexProvider } from "./search/react/provider";

import embedsRoutes from "./embeds/routes";
import folderRoutes from "./folders/routes";
import graphExplorerRoutes from "./graph-explorer/routes";
import landingRoutes from "./landing/routes";
import m10tRoutes from "./m10t/routes";
import mushafRoutes from "./mushaf/routes";
import notFoundRoute from "./not-found/routes";
import searchRoutes from "./search/routes";
import wrfRoutes from "./verse-wrf/routes";
import wirdRoutes from "./wird/routes";

import { EmbedsPlugins } from "./embeds/integration";
import { FoldersPlugins } from "./folders/integration";
import { M10tPlugins } from "./m10t/integration";
import { NotesPlugins } from "./notes/integration";
import { QuranPlugins } from "./quran/integration";

// CSS
import "@muddakir/ui/style";
import "prosemirror-quran-schema/index.css";
import { lazy, StrictMode } from "react";
import "./appendixes/index.css";
import "./dark-mode/index.css";
import "./folders/style";
import "./layout/index.css";
import "./m10t/index.css";
import "./morph/index.css";
import "./mushaf/index.css";
import "./notes/style";
import { SplashScreen } from "./splash";
import "./verse-anchors/index.css";
import "./verse-grouping/index.css";
import "./verse-markers/index.css";
import "./verse-slice-editor/index.css";
import "./wird/index.css";

const ALL_PLUGINS = [
  ...EmbedsPlugins,
  ...FoldersPlugins,
  ...M10tPlugins,
  ...QuranPlugins,
  ...NotesPlugins,
];

const container = document.body.appendChild(document.createElement("div"));
const root = createRoot(container);

container.id = "muddakir";

for (const unit of Object.values(Unit)) {
  claim(unit);
}

const createGraph = () => new MultiDirectedGraph({ allowSelfLoops: false });

const mergeGraph = async (
  graph: AbstractGraph,
  partial: Partial<SerializedGraph<AbstractGraph>>,
) => {
  let merged;

  try {
    const external = new MultiDirectedGraph({ allowSelfLoops: false });
    const imported = external.import(partial, false);
    const migrated = migrate(imported, migrations);
    const cloned = graph.copy();
    const errors = safeImportGraph(cloned, migrated, true);

    if (errors.length) {
      console.error("unable to merge graph:");

      for (const [error, detail] of errors) {
        console.error(error);
        console.error(detail);
      }

      const response = await prompt(
        `There are ${migrated.size} nodes that can be imported, but ${errors.length} will not be as they caused errors. Would you still like to use this file?\n\nType YES to confirm.`,
      );

      if (response?.toLowerCase() === "yes") {
        merged = cloned;
      }
    } else {
      merged = cloned;
    }
  } catch (e) {
    console.error("unable to merge graph");
    console.error(e);
  }

  if (merged) {
    render({ graph: merged });
  }
};

const replaceGraph = (
  graph: AbstractGraph,
  content: SerializedGraph<AbstractGraph>,
) => {
  let replaced;
  try {
    const empty = graph.emptyCopy();
    replaced = migrate(empty.import(content), migrations);
  } catch (e) {
    console.error("unable to replace graph, will use old one");
    console.error(e);
  }

  if (replaced) {
    render({
      graph: replaced,
    });
  }
};

const clearGraph = (graph: AbstractGraph) => {
  graph.clear();
  render({ graph: createGraph() });
};

const DevToolRoutes =
  process.env.NODE_ENV === "development"
    ? lazy(() => import("./devtools/routes"))
    : () => null;

const render = ({ graph }: { graph: AbstractGraph }) => {
  if (process.env.NODE_ENV === "development") {
    window.DEBUG ||= {} as any;
    window.DEBUG.graph = graph;
    window.DEBUG.rerender = () => render({ graph });
  }

  root.render(
    <StrictMode>
      <E.KeyBindingProvider>
        <E.MainMenuProvider
          items={[
            { path: ["File"] },
            { path: ["Edit"] },
            { path: ["View"] },
            { path: ["Go"] },
            { path: ["Tools"] },
          ]}
        >
          <E.PreferencesProvider
            items={{
              sidebarEnabled: true,
              m10tMode: "none",
              groupVersesBy: "page",
              clearSelectionOnMark: true,
            }}
          >
            <E.RoutingProvider
              onStartError={(err) => {
                console.error(err);
              }}
              notFound={notFoundRoute}
              // @ts-expect-error
              routes={[
                ...embedsRoutes,
                ...folderRoutes,
                ...graphExplorerRoutes,
                ...landingRoutes,
                ...m10tRoutes,
                ...mushafRoutes,
                ...searchRoutes,
                ...wirdRoutes,
                ...wrfRoutes,
              ]}
            >
              <E.GraphProvider graph={graph}>
                <FSPersistence
                  onClear={() => clearGraph(graph)}
                  onMerge={(x) => mergeGraph(graph, x)}
                  onReplace={(x) => replaceGraph(graph, x)}
                />

                <GraphHistory />

                <IDBPersistence onReplace={(x) => replaceGraph(graph, x)}>
                  <E.CriticalAssetLoader
                    assets={[load]}
                    fallback={SplashScreen}
                  >
                    <E.PluginProvider available={ALL_PLUGINS}>
                      <SearchIndexProvider>
                        <E.Switch />
                        <GoTo />
                      </SearchIndexProvider>

                      {process.env.NODE_ENV === "development" && (
                        <DevToolRoutes />
                      )}
                    </E.PluginProvider>
                  </E.CriticalAssetLoader>
                </IDBPersistence>
              </E.GraphProvider>
            </E.RoutingProvider>
          </E.PreferencesProvider>
        </E.MainMenuProvider>
      </E.KeyBindingProvider>
    </StrictMode>,
  );
};

if (process.env.NODE_ENV === "development") {
  import("./debug");
  import("./hot-reload");

  window.DEBUG ||= {} as any;
  window.DEBUG.render = render;
}

render({
  graph: createGraph(),
});
