import { useContext, useEffect } from "react";
import { ProseMirrorContext } from "react-prosemirror";
import { verseAnchorPluginKey, VerseAnchorPluginProps } from "..";
import extension from "./extension";

export function ProseMirrorVerseAnchors(props: VerseAnchorPluginProps) {
  const { docVersion, register, view } = useContext(ProseMirrorContext);

  useEffect(() => {
    return register(extension(props));
  }, []);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch(
      view.state.tr.setMeta(verseAnchorPluginKey, {
        action: "replace",
        id: props.anchor,
      }),
    );
  }, [props.anchor, view, docVersion]);

  return null;
}
