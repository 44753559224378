import { FolderMainMenuItems } from "@muddakir/folders";
import VerseGroupingMainMenuItems from "@muddakir/verse-grouping/menu";

export default function MainMenuItems({}: {}) {
  return (
    <>
      <FolderMainMenuItems />

      <VerseGroupingMainMenuItems />
    </>
  );
}
