import { Unit, classify, unitsIn } from "@muddakir/quran-db";
import invariant from "invariant";

// (path: Id[], verses: Verse[]): Verse[]
export function versesAt(
  path: UnitId[],
  resolve: UnitVerseResolver,
): VerseId[] {
  if (path.length > 1) {
    return intersect(
      resolve(peek(path)!),
      versesAt(path.slice(0, -1), resolve),
    );
  } else if (path.length === 1) {
    return resolve(path[0]!);
  } else {
    return [];
  }
}

const intersect = <T>(a: T[], b: T[]) => a.filter((x) => b.includes(x));
const peek = (x: UnitId[]): UnitId | undefined => x[x.length - 1];

// export const versesAcross = partitionBy;

export class VerseTree {
  resolve: UnitVerseResolver;
  computeSiblings: UnitSiblingComputer;

  constructor(
    resolve: UnitVerseResolver,
    computeSiblings: UnitSiblingComputer = () => [],
  ) {
    this.resolve = resolve;
    this.computeSiblings = computeSiblings;
  }

  find(path: UnitId[]): VerseTreeNode | null {
    let root = null;
    let node = null;

    for (let cursor = 0; cursor < path.length; ++cursor) {
      node = new VerseTreeNode(path.slice(0, cursor + 1), this, node);
      root ||= node;
    }

    return node;
  }
}

export class VerseTreeNode {
  id: UnitId;
  path: UnitId[];
  parent: VerseTreeNode | null;
  root: VerseTreeNode;

  constructor(
    path: UnitId[],
    tree: VerseTree,
    parentNode: VerseTreeNode | null = null,
  ) {
    invariant(path.length > 0, `path cannot be empty`);

    this.#tree = tree;
    this.id = peek(path)!;
    this.path = path;
    this.parent = parentNode;
    this.root = parentNode ? parentNode.root : this;
  }

  // TODO: memoize
  get verses(): VerseId[] {
    if (!this.#verses) {
      this.#verses = versesAt(this.path, this.#tree.resolve);
    }

    return this.#verses;
  }

  get next(): VerseTreeNode | null {
    return this.#findSiblingAt(+1);
  }

  get prev(): VerseTreeNode | null {
    return this.#findSiblingAt(-1);
  }

  #findSiblingAt(dir: 1 | -1) {
    if (this.parent) {
      const siblings = this.#tree.computeSiblings(this) || [];
      const siblingIndex = siblings.indexOf(this.id) + dir;

      if (siblings.hasOwnProperty(siblingIndex)) {
        const siblingPath = this.parent.path.concat([siblings[siblingIndex]!]);
        return new VerseTreeNode(siblingPath, this.#tree, this.parent);
      }
    }

    return null;
  }

  #tree;
  #verses: VerseId[] | undefined;
}

export const createUnitSiblingComputer = (): UnitSiblingComputer => {
  const cache = new Map<string, VerseId[]>();

  return ({ id, path, parent }) => {
    const scheme = classify(id);

    if (!scheme) {
      return [];
    }

    const key = path.slice(0, -1).concat([scheme!]).join("/");

    if (!cache.has(key)) {
      cache.set(
        key,
        scheme === Unit.Verse
          ? parent!.verses
          : unitsIn(scheme)(parent!.verses),
      );
    }

    return cache.get(key)!;
  };
};
