import { MainMenuItem } from "@muddakir/engine";

export default function NotesMainMenuItems({
  onInsert,
}: {
  onInsert: () => void;
}) {
  return (
    <>
      <MainMenuItem
        path={["Edit", "Insert Note"]}
        onTrigger={() => {
          onInsert();
          // TODO: check if actually applied
          return true;
        }}
        keyBinding="⮐"
      />
    </>
  );
}
