import { GraphContext, RoutingContext, usePreference } from "@muddakir/engine";
import Layout, { sidebarEnabledPreference } from "@muddakir/layout";
import { dropParticipant } from "@muddakir/m10t";
import { ProseMirrorMorph } from "@muddakir/morph/pm/react";
import useMorphModeInURL from "@muddakir/morph/useMorphModeInURL";
import Nav from "@muddakir/mushaf/nav";
import { QuranSelector, ScopeType } from "@muddakir/quran-verse-range";
import { VerseTreeNode } from "@muddakir/quran-verse-tree";
import { useScribe } from "@muddakir/scribe";
import { ProseMirrorVerseAnchors } from "@muddakir/verse-anchors/pm/react";
import { ProseMirrorVerseGrouping } from "@muddakir/verse-grouping/pm/react";
import { Jumper } from "@muddakir/verse-jumping";
import { jumpToAdjacentUnit } from "@muddakir/verse-jumping/pm/commands";
import { ProseMirrorVerseJumping } from "@muddakir/verse-jumping/pm/react";
import { ProseMirrorVerseSliceEditor } from "@muddakir/verse-slice-editor/react";
import { transaction } from "graphology-tx";
import AbstractGraph from "graphology-types";
import { keymap } from "prosemirror-keymap";
import { findVerseIdAtCursor } from "prosemirror-quran-schema";
import { ProseMirrorQuran } from "prosemirror-quran-schema/react";
import { ancestors } from "prosemirror-util";
import { useContext, useEffect, useRef, useState } from "react";
import { ProseMirror, ProseMirrorContext } from "react-prosemirror";
import { adjustVerseSliceRangeInFolder } from "../graph";
import MainMenuItems from "./menu";
import Sidebar from "./sidebar";
import { useFolderDocument } from "./target";
import { useFolderGroupingUnitState } from "./useFolderGrouping";

export interface VerseFolderAttrs {
  edge: GraphEdgeId;
  range: [VerseCharacterIndex, VerseCharacterIndex];
}

export interface SelectionProps {
  id: GraphNodeId;
  path: QuranSelector[];
  scopeType: ScopeType.SINGLE;
}

export interface LayoutProps {
  doc: object | null;
  node: VerseTreeNode | null;
}

export function FolderMushaf(props: SelectionProps) {
  const [editedVerse, editVerse] = useState<GraphEdgeId | undefined>(undefined);
  const {
    // doc,
    // node,
    id,
    path,
    scopeType,
  } = props;
  const [groupingUnit, setGroupingUnit] = useFolderGroupingUnitState({ id });

  const { error, node, doc } = useFolderDocument({
    path,
    id,
    scopeType,
    editedVerse,
    groupingUnit,
  });

  const { graph } = useContext(GraphContext);
  const { navigate } = useContext(RoutingContext);
  const [sidebarEnabled] = usePreference(sidebarEnabledPreference);
  const [morphMode, setMorphMode] = useMorphModeInURL();
  const {
    describe,
    describeForTitle,
    describeAmongSiblings,
    describeRoot,
    abbrevRoot,
  } = useScribe();

  useEffect(() => {
    if (node) {
      document.title = describeForTitle(node);
    }
  }, [node]);

  if (error) {
    return (
      <Layout>
        <pre>{error}</pre>
      </Layout>
    );
  }

  if (!node || !doc) {
    return <Layout />;
  }

  const genHref = (node: VerseTreeNode) => `#/${node.path.join("/")}`;
  const genNavUnit = (node: VerseTreeNode) => ({
    text: describeAmongSiblings(node)!,
    href: genHref(node),
  });

  // TODO: what happens to @href when node.parent is null?
  const parentNodeRel: { text: string; href: string } | null =
    node.root !== node
      ? {
          text:
            node.parent === node.root
              ? abbrevRoot(node.parent, scopeType)!
              : describe(node.parent!)!,
          href: `${genHref(node.parent!)}?a=${node.id}`,
        }
      : null;

  const nextNodeRel = node.next ? genNavUnit(node.next) : null;
  const prevNodeRel = node.prev ? genNavUnit(node.prev) : null;

  return (
    <Layout>
      <MainMenuItems groupingUnitControl={[groupingUnit, setGroupingUnit]} />

      <Jumper
        nextNode={nextNodeRel}
        prevNode={prevNodeRel}
        parentNode={parentNodeRel}
      />

      <div className="mushaf-and-sidebar">
        {sidebarEnabled && <Sidebar id={props.id} />}

        <div className="mushaf">
          <div
            style={{
              flexGrow: 1,
              flexShrink: 0,
              // overflow: 'auto',
            }}
          >
            <h1
              dir="rtl"
              style={{
                fontFamily: "Amiri Quran",
                fontSize: "3rem",
                fontWeight: "normal",
              }}
            >
              {isRoot(node) ? describeRoot(node, scopeType) : describe(node)}
            </h1>

            <ProseMirror doc={doc}>
              <KeyBindings
                graph={graph}
                editVerse={editVerse}
                toggleMorphMode={() => {
                  setMorphMode(!morphMode);
                }}
                onNavigateToVerse={(id) =>
                  navigate(`/${path.concat([id]).join("/")}`)
                }
                onNavigateToParentNode={() =>
                  parentNodeRel
                    ? navigate(parentNodeRel.href)
                    : Promise.reject()
                }
              />

              <ProseMirrorQuran />
              <ProseMirrorVerseGrouping />
              <ProseMirrorVerseAnchors anchor={undefined} />
              <ProseMirrorMorph enabled={!!morphMode} />
              <ProseMirrorVerseJumping />

              <ProseMirrorVerseSliceEditor
                onRead={() => {
                  editVerse(undefined);
                }}
                onChange={(edge: GraphEdgeId, marker: VerseMarker) => {
                  transaction(graph, () =>
                    adjustVerseSliceRangeInFolder(
                      graph,
                      edge,
                      marker.slice(1) as [number, number],
                    ),
                  );
                  editVerse(undefined);
                }}
                onRemove={(edge: GraphEdgeId) => {
                  dropParticipant(graph, edge);
                }}
              />
            </ProseMirror>
          </div>

          <div style={{ flexGrow: 0 }}>
            <Nav
              nextNode={nextNodeRel}
              prevNode={prevNodeRel}
              parentNode={parentNodeRel}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

const isRoot = (node: VerseTreeNode) => node.root === node;

type KeyBindingsProps = {
  graph: AbstractGraph;
  editVerse: (edge: GraphEdgeId | undefined) => void;
  toggleMorphMode: () => void;
  onNavigateToVerse: (id: VerseId) => unknown;
  onNavigateToParentNode: () => unknown;
};

function KeyBindings(props: KeyBindingsProps) {
  const { register } = useContext(ProseMirrorContext);
  const binding = useRef(props);

  binding.current = props;

  useEffect(() => {
    return register({
      plugins: [
        // bus,
        keymap({
          "Enter"(state) {
            return ancestors(state.doc, state.selection.anchor, (node) => {
              if (node.type.name === "verseSlice") {
                const { editVerse } = binding.current;

                console.log(node);
                editVerse(node.attrs.edge);

                return true;
              }

              return false;
            });
          },

          "r"() {
            binding.current.toggleMorphMode();
            return true;
          },

          "[": (_state, _dispatch, view) => jumpToAdjacentUnit(view!, "next"),
          "]": (_state, _dispatch, view) => jumpToAdjacentUnit(view!, "prev"),

          "Alt-Enter"(state) {
            const { onNavigateToVerse } = binding.current;

            if (typeof onNavigateToVerse === "function") {
              const verseId = findVerseIdAtCursor(state);

              if (verseId) {
                onNavigateToVerse(verseId);
                return true;
              }
            }

            return false;
          },

          "Alt-u"() {
            const { onNavigateToParentNode } = binding.current;

            if (onNavigateToParentNode) {
              onNavigateToParentNode();
              return true;
            }

            return false;
          },
        }),
      ],
    });
  }, []);

  return null;
}
