import { useContext, useEffect } from "react";
import { ProseMirrorContext } from "react-prosemirror";
import schema from "../schema";

export function ProseMirrorVerseWRF() {
  const { register } = useContext(ProseMirrorContext);

  useEffect(() => {
    return register({
      schema,
    });
  }, []);

  return null;
}
