import { useContext, useEffect } from "react";
import {
  createStateContainerPlugin,
  ProseMirrorContext,
} from "react-prosemirror";
import { extension, VerseSliceEditorProps } from ".";
import schema from "./schema";

type ProseMirrorVerseSliceEditorProps = Partial<VerseSliceEditorProps>;

export function ProseMirrorVerseSliceEditor(
  props: ProseMirrorVerseSliceEditorProps,
) {
  const { register, view } = useContext(ProseMirrorContext);
  const [bus, busKey] =
    createStateContainerPlugin<ProseMirrorVerseSliceEditorProps>(props);

  useEffect(() => {
    return register({
      schema,
      plugins: [bus, ...extension.plugins],
      nodeViews: {
        ...extension.nodeViews(busKey),
      },
    });
  }, []);

  useEffect(() => {
    if (view) {
      view.dispatch(view.state.tr.setMeta(busKey, props));
    }
  }, [...Object.values(props)]);

  return null;
}
