import { useURLState } from "@muddakir/engine";
import AbstractGraph from "graphology-types";
import React from "react";
import { findNotesIn } from "./graph";
import { noteEdit } from "./queryParams";

const NoTargets: GraphNodeId[] = [];

export interface NotesInURL {
  note: GraphEdgeId | undefined;
  notes: GraphEdgeId[];
  editNote: (note: GraphEdgeId | null) => void;
}

export default function useNotesInURL({
  graph,
  graphState,
  targets = NoTargets,
}: {
  graph: AbstractGraph;
  graphState: unknown;
  targets?: GraphNodeId[];
}): NotesInURL {
  const [note, editNote] = useURLState(noteEdit);
  const [notes, setNotes] = React.useState<GraphEdgeId[]>([]);

  React.useEffect(() => {
    const nextNotes = findNotesIn(graph, targets).sort();

    if (!deepEq(notes, nextNotes)) {
      setNotes(nextNotes);
    }
  }, [graphState, targets]);

  return { note, notes, editNote };
}

const deepEq = <T>(a: T, b: T) => JSON.stringify(a) === JSON.stringify(b);
