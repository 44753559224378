import { getVerseId, getVerseIndex, Unit } from "@muddakir/quran-db";
import { transaction } from "graphology-tx";
import AbstractGraph from "graphology-types";
import { createOk } from "option-t/plain_result";
import { Node, ResolvedPos } from "prosemirror-model";
import { findVerseAtPos, findVerseIdAtPos } from "prosemirror-quran-schema";
import {
  EditorState,
  NodeSelection,
  TextSelection,
  Transaction,
} from "prosemirror-state";
import { ancestors } from "prosemirror-util";
import {
  createChainBetweenVerses,
  findEnclosingChain,
  growChain,
  intoChainVerseRange,
  isChain,
  removeChain,
  shrinkChain,
} from "..";

export function linkSelectionToChain(state: EditorState, graph: AbstractGraph) {
  if (
    state.selection instanceof TextSelection &&
    state.selection.ranges.length === 1
  ) {
    const { $from, $to } = state.selection.ranges[0]!;
    const startVerse = findVerseIdAtPos(state, $from);
    const endVerse = findVerseIdAtPos(state, $to);

    if (startVerse && endVerse) {
      let chainOfFirstVerse: Node | null;
      let chainOfEndVerse: Node | null;

      ancestors(state.doc, state.selection.$from.pos, (node) => {
        if (
          node.type === state.schema.nodes.verseGroup &&
          node.attrs.type === Unit.Custom &&
          isChain(node.attrs.id)
        ) {
          chainOfFirstVerse = node;
          return true;
        }
        return false;
      });

      ancestors(state.doc, state.selection.$to.pos, (node) => {
        if (
          node.type === state.schema.nodes.verseGroup &&
          node.attrs.type === Unit.Custom &&
          isChain(node.attrs.id)
        ) {
          chainOfEndVerse = node;
          return true;
        }
        return false;
      });

      if (
        chainOfFirstVerse! &&
        chainOfEndVerse! &&
        chainOfFirstVerse === chainOfEndVerse
      ) {
        return false;
      }

      if (chainOfFirstVerse! || chainOfEndVerse!) {
        const chainNode = chainOfFirstVerse! || chainOfEndVerse!;
        const dir = chainNode === chainOfFirstVerse! ? 1 : -1;
        const until = dir === 1 ? endVerse : startVerse;

        console.log("growing chain %s to %s", chainNode.attrs.id, until);

        const result = transaction(graph, () =>
          growChain(graph, chainNode.attrs.id, until, dir),
        );

        if (result.ok) {
          console.log("ok", result.val);
        } else {
          console.error(result.err);
        }
      } else {
        console.log("creating chain between", startVerse, "to", endVerse);

        const res = transaction(graph, () =>
          createChainBetweenVerses(graph, startVerse, endVerse),
        );

        if (res.ok) {
          console.log("ok");
        } else {
          console.error(res.err);
        }
      }

      return true;
    }
  }

  return false;
}

export function linkAdjacentVerseToChain(
  state: EditorState,
  graph: AbstractGraph,
) {
  if (!(state.selection instanceof TextSelection) || !state.selection.empty) {
    return false;
  }

  const verseNode = findVerseAtPos(state, state.selection.$cursor!);

  if (!verseNode) {
    return false;
  }

  const verse = verseNode.attrs.id;
  const chain = findEnclosingChain(graph, verse);

  if (!chain) {
    return false;
  }

  const range = intoChainVerseRange(graph, chain);
  let adjacent: VerseId | undefined;
  let dir: 1 | -1 | undefined;
  // grab from back
  if (range[0] === verse) {
    try {
      adjacent = getVerseId(getVerseIndex(verse) - 1);
      dir = -1;
    } catch {
      return false;
    }
  }
  // grab from next
  else if (range[1] === verse) {
    try {
      adjacent = getVerseId(getVerseIndex(verse) + 1);
      dir = 1;
    } catch {
      return false;
    }
  }

  if (!adjacent) {
    return false;
  }

  const result = transaction(graph, () =>
    growChain(graph, chain, adjacent, dir!),
  );

  if (result.err) {
    // TODO: propagate
    console.error(result.val);
    return result;
  }

  return createOk(dir!);
}

export function selectAdjacentVerse(
  state: EditorState,
  dispatch: (tr: Transaction) => void,
  pos: ResolvedPos,
  dir: 1 | -1,
) {
  let verseGroup: [Node, number] | undefined;

  if (!(state.selection instanceof TextSelection)) {
    return false;
  }

  const verseNode = findVerseAtPos(state, pos);

  if (!verseNode) {
    return false;
  }

  ancestors(state.doc, pos.pos, (node, pos) => {
    console.log("parent:", node, pos);
    if (node.type === state.schema.nodes.verseGroup) {
      verseGroup = [node, pos];
      return true;
    }
    return false;
  });

  if (!verseGroup) {
    return true;
  }

  let adjacents: [number | null, number | null] = [null, null];
  let previous: number | null = null;
  verseGroup[0].descendants((node, pos) => {
    if (adjacents[0] !== null && adjacents[1] !== null) {
      return false;
    } else if (
      adjacents[0] !== null &&
      node.type === state.schema.nodes.verse
    ) {
      adjacents[1] = pos + verseGroup![1];
      return false;
    } else if (node === verseNode) {
      adjacents[0] = previous;
      previous = null;
      return false;
    } else if (node.type === state.schema.nodes.verse) {
      previous = pos + verseGroup![1];
      return false;
    }

    return true;
  });

  let nextCursorPos: number | undefined;

  if (adjacents[0] !== null && dir === -1) {
    nextCursorPos = adjacents[0];
  } else if (adjacents[1] !== null && dir === 1) {
    nextCursorPos = adjacents[1];
  }

  if (nextCursorPos !== undefined) {
    dispatch(
      state.tr.setSelection(TextSelection.create(state.doc, nextCursorPos)),
    );
    return true;
  }

  return false;
}

export function dropSelectedChain(state: EditorState, graph: AbstractGraph) {
  if (
    state.selection instanceof NodeSelection &&
    state.selection.node.type === state.schema.nodes.verseGroup &&
    state.selection.node.attrs.type === Unit.Custom &&
    isChain(state.selection.node.attrs.id)
  ) {
    const chain = state.selection.node.attrs.id;
    transaction(graph, () => removeChain(graph, chain));
    return true;
  }

  return false;
}

export function unlinkCursorFromChain(
  state: EditorState,
  graph: AbstractGraph,
) {
  const { selection } = state;

  if (!(selection instanceof TextSelection) || !selection.empty) {
    return false;
  }

  const verseNode = selection.$cursor?.node(selection.$cursor?.depth);

  if (!verseNode || verseNode.type !== state.schema.nodes.verse) {
    return false;
  }

  return unlink(state, graph, verseNode);
}

export function unlinkSelectionFromChain(
  state: EditorState,
  graph: AbstractGraph,
) {
  if (
    state.selection instanceof NodeSelection &&
    state.selection.node.type === state.schema.nodes.verse
  ) {
    return unlink(state, graph, state.selection.node);
  }

  return false;
}

function unlink(state: EditorState, graph: AbstractGraph, verseNode: Node) {
  let chainNode: Node | null;

  ancestors(state.doc, state.selection.$anchor.pos, (node) => {
    if (
      node.type === state.schema.nodes.verseGroup &&
      node.attrs.type === Unit.Custom &&
      isChain(node.attrs.id)
    ) {
      chainNode = node;
      return true;
    }
    return false;
  });

  if (chainNode!) {
    const result = transaction(graph, () =>
      shrinkChain(graph, chainNode!.attrs.id, verseNode.attrs.id),
    );

    if (result.ok) {
    } else {
      console.error(result.err);
    }

    return true;
  }

  return false;
}
