import { GraphContext, RoutingContext, t } from "@muddakir/engine";
import PanelHeader from "@muddakir/ui/PanelHeader";
import { transaction } from "graphology-tx";
import Tree from "rc-tree";
import React, { useState } from "react";
import { MdAdd, MdBookmark, MdDelete, MdEdit } from "react-icons/md";
import {
  add as addFolderToGraph,
  addVersesToFolder,
  canSafelyRemove,
  getAll as getFoldersFromGraph,
  remove as removeFolderFromGraph,
  update as updateFolderInGraph,
} from "./graph";
import { Folder, FolderAttributes } from "./types";

export default function FolderBrowser({
  onRemove,
  verseMarkers,
}: {
  onRemove: (folderId: GraphNodeId) => void;
  verseMarkers: VerseMarker[];
}) {
  const folders = useFolderLoader();
  const [activeFolderId, activateFolder] = React.useState<string | undefined>(
    undefined,
  );
  const [addingFolder, showFolderCreator] = React.useState(false);
  const [editingFolder, showFolderEditor] = React.useState(false);
  const { navigate } = React.useContext(RoutingContext);
  const { graph } = React.useContext(GraphContext);
  const addFolder = (attrs: FolderAttributes) => {
    transaction(graph, () => addFolderToGraph(graph, attrs));
    showFolderCreator(false);
  };

  const updateFolder = (attrs: FolderAttributes) => {
    if (activeFolderId) {
      transaction(graph, () =>
        updateFolderInGraph(graph, activeFolderId, attrs),
      );
      showFolderEditor(false);
    }
  };

  const addVersesToSelectedFolder = () => {
    if (activeFolderId) {
      transaction(graph, () =>
        addVersesToFolder(graph, activeFolderId, verseMarkers),
      );
    }
  };

  const navigateToFolder = (folderId: GraphNodeId) => {
    navigate(`/${folderId}`);
  };

  const navigateToActiveFolder = () => {
    if (activeFolderId) {
      navigateToFolder(activeFolderId);
    }
  };

  const confirmFolderRemoval = () => {
    if (!activeFolderId) {
      return;
    }

    if (!canSafelyRemove(graph, activeFolderId)) {
      if (
        !window.confirm(
          "You are about to remove a folder that is not empty. Are you sure?",
        )
      ) {
        return;
      }
    }

    transaction(graph, () => removeFolderFromGraph(graph, activeFolderId));
    onRemove(activeFolderId);
  };

  type FolderInTree = { key: string; title: string; children: FolderInTree[] };

  const treeifyFolder = (folder: Folder): FolderInTree => ({
    key: folder.id,
    title: (
      <span>
        {`${folder.name} `}{" "}
        <span
          style={{ fontSize: "0.8rem", opacity: 0.4 }}
        >{`(${folder.entries})`}</span>
      </span>
    ),
    children: folders.filter((x) => x.inside === folder.id).map(treeifyFolder),
  });

  const treeData = folders.filter((x) => !x.inside).map(treeifyFolder);

  return (
    <div className="folder-panel">
      <PanelHeader>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {t("Folders", "المجلدات")}

          <div style={{ gap: "0 0.5rem", display: "flex" }}>
            <button
              onClick={() => showFolderCreator(true)}
              title={t(`Add a Folder`, `أضف مجلد`)}
              className="icon-button"
            >
              <MdAdd />
            </button>

            <button
              disabled={!activeFolderId}
              onClick={() => showFolderEditor(true)}
              title={t("Edit", "عدل")}
              className="icon-button"
            >
              <MdEdit />
            </button>

            <button
              disabled={!activeFolderId}
              onClick={confirmFolderRemoval}
              title={t("Delete", "احذف")}
              className="icon-button"
            >
              <MdDelete />
            </button>

            <button
              disabled={!activeFolderId || verseMarkers.length === 0}
              onClick={addVersesToSelectedFolder}
              title={t("Add Marked Verses", "اضف الايات المعلمة")}
              className="icon-button"
            >
              <MdBookmark />
            </button>
          </div>
        </div>
      </PanelHeader>

      {addingFolder && (
        <FolderCreator
          initialParentId={activeFolderId}
          folders={folders}
          onSave={addFolder}
          onCancel={() => showFolderCreator(false)}
        />
      )}

      {editingFolder && (
        <FolderCreator
          initialParentId={activeFolderId}
          initialName={folders.find((x) => x.id === activeFolderId)?.name}
          folders={folders}
          onSave={updateFolder}
          onCancel={() => showFolderEditor(false)}
        />
      )}

      <Tree
        virtual={true}
        defaultExpandAll
        showLine
        expandAction="click"
        onSelect={(keys) => activateFolder(keys[0] as string | undefined)}
        treeData={treeData}
        onDoubleClick={(e, attrs) => {
          e.preventDefault();
          navigateToFolder(attrs.key);
        }}
        showIcon={false}
        switcherIcon={() => null}
      />
    </div>
  );
}

function FolderCreator({
  folders,
  initialName,
  initialParentId,
  onCancel,
  onSave,
}: {
  folders: Folder[];
  initialName?: string | undefined;
  initialParentId?: GraphNodeId | undefined;
  onCancel: () => void;
  onSave: ({
    name,
    parentId,
  }: {
    name: string;
    parentId: string | undefined;
  }) => void;
}) {
  const [folderName, setFolderName] = React.useState(initialName || "");
  const [parentId, setParentId] = React.useState<GraphNodeId | undefined>(
    initialParentId || undefined,
  );

  return (
    <form
      style={{ margin: "1rem 0" }}
      onSubmit={(e) => e.preventDefault()}
    >
      <label style={{ display: "block" }}>
        {t("Name:", "الاسم:")}{" "}
        <input
          type="text"
          onChange={(e) => setFolderName(e.target.value)}
          value={folderName}
        />
      </label>
      <label style={{ display: "block" }}>
        {t("Parent:", "المجلد الحاوي:")}{" "}
        <select
          value={parentId}
          onChange={(e) => setParentId(e.target.value)}
        >
          <option
            key={"-"}
            value=""
          >
            -
          </option>

          {folders.map((folder) => (
            <option
              key={folder.id}
              value={folder.id}
            >
              {folder.name || folder.id}
            </option>
          ))}
        </select>
      </label>
      <button
        type="submit"
        onClick={() => onSave({ name: folderName, parentId })}
      >
        {initialName ? t("Save", "احفظ") : t("Add", "أضف")}
      </button>{" "}
      <button
        type="button"
        onClick={onCancel}
      >
        {t("Cancel", "الغي")}
      </button>
    </form>
  );
}

function useFolderLoader() {
  const [value, setValue] = useState<Folder[]>([]);
  const { graph, graphState } = React.useContext(GraphContext);

  React.useEffect(() => {
    setValue(getFoldersFromGraph(graph));
  }, [graph, graphState]);

  return value;
}
