import { MuddakirPlugin } from "#plugins";
import { linkToUnit, linkToUnitWithAnchor } from "@muddakir/verse-anchors";
import {
  SearchConsoleEntryPlugin,
  SearchIndexDocumentsPlugin,
} from "../search";
import { getNotesForSearchIndex, isEmbeddedNoteInIndex, isNote } from "./graph";

export const NotesPlugins: MuddakirPlugin[] = [
  [
    "scribe",
    {
      describe(graph, { id }, _lod, describe) {
        if (isNote(id)) {
          const notable = graph.findNeighbor(id, () => true);
          if (!notable) {
            console.log("note with no notable???", id);
            return undefined;
          }

          return `ملاحضة حول (${describe({ id: notable })})`;
        } else if (graph.hasEdge(id) && graph.getEdgeAttribute(id, "note")) {
          return `ملاحضة بين (${describe({ id: graph.source(id) })}) و (${describe({ id: graph.target(id) })})`;
        }

        return undefined;
      },
      describeClass({ id }) {
        if (isNote(id)) {
          return `ملاحظة`;
        }
        return undefined;
      },
    },
  ],
  [
    "search.index-documents",
    async (graph) => {
      return getNotesForSearchIndex(graph);
    },
  ] as ["search.index-documents", SearchIndexDocumentsPlugin],
  [
    "search.console-entry",
    (graph, scribe, document) => {
      if (isEmbeddedNoteInIndex(document)) {
        return {
          id: scribe.describe({ id: document.id }),
          href: linkToUnit(graph.source(document.id)),
        };
      } else if (isNote(document.id)) {
        const edge = graph.findOutboundEdge(document.id, () => true);
        if (edge && !!graph.getEdgeAttribute(edge, "range")) {
          return {
            id: scribe.describe({ id: document.id }),
            href: linkToUnit(graph.target(edge)),
          };
        } else {
          const notable = graph.findNeighbor(document.id, () => true);
          if (notable) {
            return {
              id: scribe.describe({ id: document.id }),
              href: linkToUnitWithAnchor(notable, document.id),
            };
          }
        }
      }

      return undefined;
    },
  ] as ["search.console-entry", SearchConsoleEntryPlugin],
];
