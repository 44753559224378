import { EditorView } from "prosemirror-view";
import { findUnitAfter, findUnitBefore, jumpToPos } from "..";

export function jumpToAdjacentUnit(view: EditorView, dir: "next" | "prev") {
  const state = view.state;
  const adjacent =
    dir === "next"
      ? findUnitAfter(state, state.schema.nodes.verseGroup!)
      : findUnitBefore(state, state.schema.nodes.verseGroup!);

  if (adjacent !== undefined && adjacent >= 0) {
    const withJump = jumpToPos(state, adjacent);

    if (withJump) {
      view.dispatch(withJump);
      return true;
    }
  }

  return false;
}
