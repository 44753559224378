import { MainMenuItem, RoutingContext } from "@muddakir/engine";
import React from "react";

export const Jumper = ({
  prevNode,
  nextNode,
  parentNode,
}: {
  prevNode: { href: string } | null;
  nextNode: { href: string } | null;
  parentNode: { href: string } | null;
}) => {
  const { navigate } = React.useContext(RoutingContext);

  return (
    <>
      <MainMenuItem
        path={["Go", "Jump to Next Group"]}
        onTrigger={() => {
          if (!nextNode) {
            return false;
          }
          navigate(nextNode.href);
          return true;
        }}
        accel="n"
        keyBinding="["
        disabled={!nextNode}
      />

      <MainMenuItem
        path={["Go", "Jump to Previous Group"]}
        onTrigger={() => {
          if (!prevNode) {
            return false;
          }
          navigate(prevNode.href);
          return true;
        }}
        accel="v"
        keyBinding="]"
        disabled={!prevNode}
      />

      <MainMenuItem
        path={["Go", "Jump to Outer Group"]}
        onTrigger={() => {
          if (!parentNode) {
            return false;
          }
          navigate(parentNode.href);
          return true;
        }}
        accel="v"
        keyBinding="alt+ArrowUp"
        disabled={!parentNode}
      />
    </>
  );
};
