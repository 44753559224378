import { MainMenuItem, RoutingContext } from "@muddakir/engine";
import { Unit } from "@muddakir/quran-db";
import { SearchPalette } from "@muddakir/search/react";
import { PaletteDialog } from "@muddakir/ui/Palette";
import React from "react";

export default function GoTo() {
  const [activePalette, activatePalette] = React.useState<string | null>(null);
  const { navigate } = React.useContext(RoutingContext);
  const navigateToWird = () => {
    navigate("/wird");
  };

  return (
    <>
      <MainMenuItem
        path={["Go", "Anywhere"]}
        onTrigger={() => {
          activatePalette("search");
          return true;
        }}
        accel="a"
        keyBinding="Ctrl+P"
      />

      <MainMenuItem
        path={["Go", "Chapter"]}
        onTrigger={() => {
          activatePalette("chapter");
          return true;
        }}
        accel="c"
        keyBinding=":"
      />

      <MainMenuItem
        path={["Go", "Page"]}
        onTrigger={() => {
          activatePalette("page");
          return true;
        }}
        accel="p"
        keyBinding="#"
      />

      <MainMenuItem
        path={["Go", "Verse"]}
        onTrigger={() => {
          activatePalette("verse");
          return true;
        }}
        accel="v"
        keyBinding="'"
      />

      <MainMenuItem
        path={["Go", "Folder"]}
        label="Go Folder"
        onTrigger={() => {
          activatePalette("folder");
          return true;
        }}
        keyBinding="ctrl+alt+F"
        accel="f"
      />

      <MainMenuItem
        path={["Go", "Wird"]}
        onTrigger={() => {
          navigateToWird();
          return true;
        }}
        accel="w"
      />

      <MainMenuItem
        path={["Go", "Graph"]}
        onTrigger={() => {
          navigate("/graph");
          return true;
        }}
        accel="g"
      />

      {activePalette === "search" && (
        <PaletteDialog dir="rtl">
          <SearchPalette onClose={() => activatePalette(null)} />
        </PaletteDialog>
      )}

      {activePalette === "folder" && (
        <PaletteDialog dir="rtl">
          <SearchPalette
            onClose={() => activatePalette(null)}
            unitFilter={`f`}
          />
        </PaletteDialog>
      )}

      {activePalette === "chapter" && (
        <PaletteDialog dir="rtl">
          <SearchPalette
            onClose={() => activatePalette(null)}
            unitFilter={Unit.Chapter}
          />
        </PaletteDialog>
      )}

      {activePalette === "page" && (
        <PaletteDialog dir="rtl">
          <SearchPalette
            onClose={() => activatePalette(null)}
            unitFilter={Unit.Page}
          />
        </PaletteDialog>
      )}

      {activePalette === "verse" && (
        <PaletteDialog dir="rtl">
          <SearchPalette
            onClose={() => activatePalette(null)}
            unitFilter={Unit.Verse}
          />
        </PaletteDialog>
      )}
    </>
  );
}
