import { MuddakirPlugin } from "#plugins";
import { t } from "@muddakir/engine";
import AbstractGraph from "graphology-types";
import { getFolderDescriptors, getFolderName, isFolder } from "./graph";

export const FoldersPlugins: MuddakirPlugin[] = [
  [
    "search.index-documents",
    async (graph: AbstractGraph) => {
      return getFolderDescriptors(graph).map((x) => ({
        id: x.id,
        title: x.name,
      }));
    },
  ],

  [
    "scribe",
    {
      describe(graph, { id }) {
        if (isFolder(id)) {
          try {
            return getFolderName(graph, id);
          } catch (e) {
            console.error(e);
            return "<<uknown>>";
          }
        }
      },

      describeClass({ id }) {
        if (isFolder(id)) {
          return t("Folder", "مجلد");
        }

        return undefined;
      },
    },
  ],
];
