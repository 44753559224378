import { Node } from "prosemirror-model";

// inverse of Node.descendants()
export const ancestors = (
  doc: Node,
  pos: number,
  f: (node: Node, pos: number) => boolean,
) => {
  const startingPos = doc.resolve(pos);
  let depth = -1;
  let cursor;

  while ((cursor = startingPos.node(depth--))) {
    if (f(cursor, startingPos.posAtIndex(0, depth + 1))) {
      return true;
    }
  }

  return false;
};
