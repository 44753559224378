import { GraphContext, PluginContext } from "@muddakir/engine";
import { classify, Unit } from "@muddakir/quran-db";
import { expand } from "@muddakir/quran-verse-range";
import {
  createUnitSiblingComputer,
  VerseTree,
} from "@muddakir/quran-verse-tree";
import { computeVerseHref } from "@muddakir/verse-anchors";
import { groupVersesBy } from "@muddakir/verse-grouping";
import React, { useContext, useMemo, useState } from "react";
import { LayoutProps, SelectionProps } from ".";
import { getVersesInFolder, isFolder } from "../graph";
import { VerseInFolder } from "../types";
import layout from "./layout";

export const useFolderDocument = ({
  path,
  id,
  scopeType,
  editedVerse,
  groupingUnit,
}: SelectionProps & {
  editedVerse: GraphEdgeId | undefined;
  groupingUnit: Unit;
}): LayoutProps & {
  error: string | null;
} => {
  const { graph, graphState } = React.useContext(GraphContext);
  const { enabled: plugins } = useContext(PluginContext);
  const [error, setError] = useState<string | null>(null);

  const groupVerses = useMemo<Record<VerseId, VerseInFolder> | null>(() => {
    try {
      return indexById(getVersesInFolder(graph, id));
    } catch (e) {
      setError(() => (e as Error).message);
      return null;
    }
  }, [id, graphState]);

  const tree = React.useMemo(
    () =>
      new VerseTree((id) => {
        try {
          return isFolder(id)
            ? getVersesInFolder(graph, id).map((x) => x.id)
            : expand([id]);
        } catch (e) {
          console.error(e);
          setError(
            () => `Could not find folder or verses in folder with ID "${id}"`,
          );
          return [];
        }
      }, createUnitSiblingComputer()),
    [id],
  );

  const { doc, node } = React.useMemo(() => {
    const node = tree.find(path);

    if (!node) {
      return { doc: null, node: null };
    }

    const hrefComputer = computeVerseHref({ node });
    const verses = node.verses
      .map((id) => groupVerses?.[id])
      .filter((x) => !!x);
    const versesWithHref = verses.map((x) => ({ ...x, ...hrefComputer(x) }));

    const groups = groupVersesBy({
      groupingUnit,
      node,
      versePool: versesWithHref,
    });

    const doc = {
      type: "doc",
      content: layout(
        {
          graph,
          nodeType: classify(node.id),
          groupingUnit,
          scopeType,
          plugins,
          editedVerse,
        },
        groups,
      ),
    };

    return { node, doc };
  }, [tree, JSON.stringify(path), graphState, groupingUnit, editedVerse]);

  return { doc, node, error };
};

const indexById = <T extends { id: string }>(list: T[]) =>
  list.reduce(
    (acc, x) => {
      acc[x.id] = x;
      return acc;
    },
    {} as Record<string, T>,
  );
