import { parse, ScopeType } from "@muddakir/quran-verse-range";
import WRFMushaf from "./mushaf.jsx";
export default [
  {
    queryParams: {},

    path: ({ url }: { url: string }) => {
      const fragments = url.split("/");

      if (!fragments.length) {
        return null;
      } else if (fragments[fragments.length - 1] !== "words") {
        return null;
      }

      let roots: string | [string, string] | string[] | undefined = [];
      let scopeType: ScopeType | undefined;

      for (const [index, fragment] of fragments.slice(0, -1).entries()) {
        if (index === 0) {
          [roots, scopeType] = parse(fragment) || [];

          if (!scopeType) {
            return null;
          }
        } else if (!parse(fragment)) {
          return null;
        }
      }

      return {
        component: WRFMushaf,
        params: {
          path: fragments.slice(0, -1),
          roots,
          scopeType: scopeType!,
        },
      };
    },
  },
];
