/** @jsxImportSource pmjsx */

import { Unit } from "@muddakir/quran-db";
import { VerseLinkAttrs } from "..";

export function VerseNumber({
  anchor,
  nodeType,
  groupingUnit,
  verse,
}: {
  anchor: string | null | undefined;
  nodeType: Unit | null | undefined;
  groupingUnit: Unit | null | undefined;
  verse: { id: string; number: number } & VerseLinkAttrs;
}) {
  if (nodeType === Unit.Verse || groupingUnit === Unit.Verse) {
    return null;
  }

  return (
    <pm:verse-number
      id={verse.id}
      altHref={verse.altHref}
      active={anchor === verse.id}
    >
      {`${verse.number}`}
    </pm:verse-number>
  );
}
