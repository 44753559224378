import { useForceReload } from "@muddakir/engine";
import { getVerseText } from "@muddakir/quran-db";
import {
  anchorToVerseInChapter,
  verseAnchorSchema,
  VerseLinkType,
} from "@muddakir/verse-anchors";
import { verseSliceSchema } from "@muddakir/verse-slice-editor";
import { ProseMirrorVerseSliceEditor } from "@muddakir/verse-slice-editor/react";
import { MultiDirectedGraph } from "graphology";
import AbstractGraph from "graphology-types";
import { keymap } from "prosemirror-keymap";
import { Schema, type Node } from "prosemirror-model";
import prosemirrorQuranSchema from "prosemirror-quran-schema";
import { ProseMirrorQuran } from "prosemirror-quran-schema/react";
import { PluginKey } from "prosemirror-state";
import { ancestors } from "prosemirror-util";
import { EditorView, NodeView } from "prosemirror-view";
import { StrictMode, useContext, useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import {
  createStateContainerPlugin,
  ProseMirror,
  ProseMirrorContext,
} from "react-prosemirror";
import {
  adjustRange,
  dropParticipant,
  getGroupForParticipant,
  getParticipatingVerses,
} from "./graph";
import { GroupEditor } from "./groupEditor";
import { M10tSlotNode } from "./schema";

export interface M10tSlotViewProps {
  graph: AbstractGraph;
  m10tEdit: GraphEdgeId | undefined;
  setM10tEdit: (nextValue: GraphEdgeId | null) => void;
}

export default class M10tSlotView<T extends M10tSlotViewProps>
  implements NodeView
{
  dom: HTMLDivElement;
  update;
  destroy;

  constructor(
    node: M10tSlotNode,
    view: EditorView,
    _getPos: Function,
    binding: PluginKey<T>,
    { onDismiss }: { onDismiss: () => void },
  ) {
    const dom = document.createElement("div");
    const root = createRoot(dom.appendChild(document.createElement("div")));
    const props = binding.getState(view.state)!;

    this.dom = dom;
    this.dom.className = node.attrs.className || "";
    this.update = (other: Node) => {
      if (other.type !== node.type) {
        return false;
      }

      const props = binding.getState(view.state)!;

      root.render(
        <StrictMode>
          <M10tSlot
            edge={node.attrs.edge}
            anchor={node.attrs.anchor}
            graph={props.graph}
            m10tEdit={props.m10tEdit}
            onDismiss={onDismiss}
          />
        </StrictMode>,
      );

      return true;
    };

    this.destroy = () => {
      setTimeout(() => root.unmount());
    };

    root.render(
      <StrictMode>
        <M10tSlot
          edge={node.attrs.edge}
          anchor={node.attrs.anchor}
          graph={props.graph}
          m10tEdit={props.m10tEdit}
          onDismiss={onDismiss}
        />
      </StrictMode>,
    );
  }

  setSelection() {}

  stopEvent(e: Event) {
    return this.dom.contains(e.target! as HTMLElement);
  }

  ignoreMutation() {
    return true;
  }
}

function M10tSlot({
  anchor,
  edge,
  graph,
  onDismiss,
  m10tEdit,
}: {
  anchor: VerseLinkType | undefined;
  edge: GraphEdgeId;
  graph: MultiDirectedGraph;
  onDismiss: () => void;
  m10tEdit: GraphEdgeId | undefined;
}) {
  // const [editing, setIsEditing] = useState(false);
  // const [editing, setIsEditing] = useState(edge === m10tEdit);
  const forceReload = useForceReload();

  // React.useEffect(() => {
  //   setIsEditing(m10tEdit === edge);
  // }, [edge, m10tEdit]);

  // when group is destroyed, the view might be updated before it's removed
  if (!graph.hasEdge(edge)) {
    return null;
  }

  const group = getGroupForParticipant(graph, edge);

  return (
    <>
      {getParticipatingVerses(graph, edge).map((participation) => (
        <Verse
          anchor={anchor}
          key={participation.edge}
          graph={graph}
          participation={participation}
          onChange={forceReload}
        />
      ))}

      {m10tEdit === edge && (
        <GroupEditor
          autoFocus
          graph={graph}
          group={group}
          onDismiss={onDismiss}
          onChange={forceReload}
        />
      )}
    </>
  );
}

// so that it gets the defaults
const VerseSchema = new Schema({
  nodes: {
    ...prosemirrorQuranSchema.nodes,
    ...verseSliceSchema.nodes,
    ...verseAnchorSchema.nodes,
  },
  marks: {
    ...prosemirrorQuranSchema.marks,
    ...verseSliceSchema.marks,
    ...verseAnchorSchema.marks,
  },
});

function Verse({
  anchor,
  graph,
  participation,
  onChange,
}: {
  anchor: VerseLinkType | undefined;
  graph: AbstractGraph;
  participation: M10tParticipation;
  onChange: () => void;
}) {
  const [editing, toggleEditor] = useState<boolean>(false);
  const anchorNode =
    anchor && ["plaintext", "hyperlink"].includes(anchor)
      ? [
          VerseSchema.text(" "),
          VerseSchema.nodeFromJSON(
            anchorToVerseInChapter({ id: participation.id, type: anchor! }),
          ),
        ]
      : [];

  const doc = useMemo(() => {
    if (editing) {
      return VerseSchema.nodes.doc.create({ id: participation.id }, [
        VerseSchema.nodes.verseSliceEditor.create(
          {
            edge: participation.edge,
            marker: participation.marker,
            anchor: null,
          },
          [
            VerseSchema.nodes.verse.create({ id: participation.marker[0] }, [
              VerseSchema.text(getVerseText(participation.marker[0])),
            ]),
          ],
        ),
        ...anchorNode,
      ]);
    }

    return VerseSchema.nodes.doc.create({ id: participation.id }, [
      VerseSchema.nodes.verseSlice.create({ edge: participation.edge }, [
        VerseSchema.nodes.verse.create({ id: participation.marker[0] }, [
          VerseSchema.text(
            getVerseText(participation.marker[0]).slice(
              ...(participation.marker.slice(1) as [number, number]),
            ),
          ),
        ]),
      ]),
      ...anchorNode,
    ]);
  }, [editing, participation]);

  return (
    <ProseMirror doc={doc}>
      <ProseMirrorQuran />

      <VerseKeyBindings
        graph={graph}
        editVerse={() => toggleEditor(true)}
      />

      <ProseMirrorVerseSliceEditor
        onRead={() => {
          toggleEditor(false);
        }}
        onChange={(edge: GraphEdgeId, marker: VerseMarker) => {
          adjustRange(graph, edge, marker.slice(1) as [number, number]);
          toggleEditor(false);
          onChange();
        }}
        onRemove={(edge: GraphEdgeId) => {
          dropParticipant(graph, edge);
          onChange();
        }}
      />
    </ProseMirror>
  );
}

type VerseKeyBindingsProps = {
  graph: AbstractGraph;
  editVerse: (id: VerseId | null) => void;
};

function VerseKeyBindings(props: VerseKeyBindingsProps) {
  const { register } = useContext(ProseMirrorContext);
  const [bus, binding] =
    createStateContainerPlugin<VerseKeyBindingsProps>(props);

  useEffect(() => {
    return register({
      plugins: [
        bus,
        keymap({
          Enter(state) {
            return ancestors(state.doc, state.selection.anchor, (node) => {
              if (node.type.name === "verseSlice") {
                const { editVerse } = binding.getState(state)!;

                editVerse(node.attrs.edge);

                return true;
              }

              return false;
            });
          },
        }),
      ],
    });
  }, []);

  return null;
}
