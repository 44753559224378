import AbstractGraph from "graphology-types";
import { useMemo } from "react";
import { isFolder } from "./graph";
import { VersesInFolders } from "./types";

export function useVersesInFolders({
  graph,
  graphState,
  verses,
}: {
  graph: AbstractGraph;
  graphState: unknown;
  verses: VerseId[];
}) {
  return useMemo<VersesInFolders>(() => {
    const acc: VersesInFolders = {};
    const cache: Record<GraphNodeId, { id: string; name: string }> = {};
    const verseSet = new Set(verses);

    graph.forEachNode((folderId, attrs) => {
      if (isFolder(folderId)) {
        let folder = cache[folderId];

        if (!folder) {
          folder = cache[folderId] = {
            id: folderId,
            name: attrs.name,
          };
        }

        graph.forEachOutEdge(folderId, (_edge, _attrs, _source, target) => {
          if (verseSet.has(target)) {
            acc[target] ||= new Set();
            acc[target].add(folder);
          }
        });
      }
    });

    return acc;
  }, [verses, graphState]);
}
