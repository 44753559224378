import { MuddakirPlugin } from "#plugins";
import { getGroupTarget, isM10t } from "./graph";

export const M10tPlugins: MuddakirPlugin[] = [
  [
    "scribe",
    {
      describe(graph, { id }) {
        if (isM10t(id)) {
          return `المتشابهات في (${getGroupTarget(graph, id)})`;
        }

        return undefined;
      },
    },
  ],
];
