import { PluginKey } from "prosemirror-state";
import { useContext, useEffect } from "react";
import {
  ProseMirrorContext,
  createStateContainerPlugin,
} from "react-prosemirror";
import notesExtension, { EditorProps } from "./pm";
import { schema as notesSchema } from "./schema";

export function ProseMirrorNotes(
  props: EditorProps & { binding?: PluginKey<EditorProps> | undefined },
) {
  const { register, view } = useContext(ProseMirrorContext);
  const [propsPlugin, binding] = createStateContainerPlugin<EditorProps>(
    props,
    props.binding,
  );

  useEffect(() => {
    return register({
      schema: notesSchema,
      plugins: [propsPlugin, ...notesExtension.plugins(binding)],
      nodeViews: notesExtension.nodeViews(binding),
    });
  }, []);

  useEffect(() => {
    if (view) {
      view.dispatch(view.state.tr.setMeta(binding, props));
    }
  }, [...Object.values(props)]);

  return null;
}
