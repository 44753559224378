import { claim, genEdgeKey } from "@muddakir/graph";
import {
  getVerseId,
  getVerseIndex,
  isVerse,
  versesBetween,
} from "@muddakir/quran-db";
import AbstractGraph from "graphology-types";
import invariant from "invariant";
import { createErr, createOk, type Result } from "option-t/plain_result";

const Chain = claim("C");

enum ChainError {
  RangeUnavailable = "RangeUnavailable",
  VerseNotAtEdgeCannotBeLinked = "VerseNotAtEdgeCannotBeLinked",
  ChainTooSmall = "ChainTooSmall",
  VerseBelongsToAnotherChain = "VerseBelongsToAnotherChain",
}

type VerseIndexRange = [number, number];

export const isChain = Chain.is;

export function createChainBetweenVerses(
  graph: AbstractGraph,
  start: VerseId,
  end: VerseId,
): Result<GraphNodeId, ChainError> {
  const mine = [start, end].map(getVerseIndex) as [number, number];

  let rangeAlreadyTaken = false;

  graph.someNode((node) => {
    if (Chain.is(node)) {
      const other = graph
        .filterOutboundEdges(node, (_e, _a, _s, target) => isVerse(target))
        .map((x) => getVerseIndex(graph.target(x)))
        .sort() as [number, number];

      invariant(
        other.length === 2,
        `expected chain to be linked to exactly two verses`,
      );
      // const other = attrs.range.map(getVerseIndex) as [number, number];

      if (other[0] >= mine[0] && other[1] <= mine[1]) {
        rangeAlreadyTaken = true;
      } else if (mine[0] >= other[0] && mine[1] <= other[1]) {
        rangeAlreadyTaken = true;
      }

      if (rangeAlreadyTaken) {
        return true;
      }
    }

    return false;
  });

  if (rangeAlreadyTaken) {
    return createErr(ChainError.RangeUnavailable);
  }

  const id = graph.addNode(Chain.gen());

  if (!graph.hasNode(start)) {
    graph.addNode(start);
  }

  if (!graph.hasNode(end)) {
    graph.addNode(end);
  }

  graph.addEdgeWithKey(genEdgeKey(), id, start);
  graph.addEdgeWithKey(genEdgeKey(), id, end);

  return createOk(id);
}

export function intoChainVerseRange(
  graph: AbstractGraph,
  chain: GraphNodeId,
): [VerseId, VerseId] {
  return graph
    .filterOutboundEdges(chain, (_e, _a, _s, target) => isVerse(target))
    .map((x) => graph.target(x))
    .sort() as [VerseId, VerseId];
}

export function findChainsAcrossUnit(
  graph: AbstractGraph,
  boundary: UnitId,
): GraphNodeId[] {
  const outerRange = versesBetween([boundary, boundary]).map(
    getVerseIndex,
  ) as VerseIndexRange;
  return graph.filterNodes((name) => {
    if (isChain(name)) {
      const range = intoChainVerseRange(graph, name).map(
        getVerseIndex,
      ) as VerseIndexRange;
      return intersects(outerRange, range);
    } else {
      return false;
    }
  });
}

export function findEnclosingChain(
  graph: AbstractGraph,
  verse: VerseId,
): GraphNodeId | undefined {
  const verseIndex = getVerseIndex(verse);

  return graph.findNode((name) => {
    if (isChain(name)) {
      const range = intoChainVerseRange(graph, name).map(
        getVerseIndex,
      ) as VerseIndexRange;
      return includes(range, verseIndex);
    } else {
      return false;
    }
  });
}

export function removeChain(graph: AbstractGraph, chain: GraphNodeId) {
  if (graph.hasNode(chain)) {
    graph.dropNode(chain);
  }
}

export function shrinkChain(
  graph: AbstractGraph,
  chain: GraphNodeId,
  verseAtEdge: VerseId,
) {
  const edges = graph.filterOutboundEdges(chain, (_e, _a, _s, target) =>
    isVerse(target),
  );
  const versesAtEdge = edges.map((x) => graph.target(x)).sort();
  const verseIndicesAtEdge = versesAtEdge.map(getVerseIndex) as [
    number,
    number,
  ];

  if (verseIndicesAtEdge[1] - verseIndicesAtEdge[0] < 1) {
    return createErr(ChainError.ChainTooSmall);
  }

  if (verseAtEdge === versesAtEdge[0]) {
    const verseIndex = getVerseIndex(versesAtEdge[0]);
    const target = getVerseId(verseIndex + 1);

    if (!graph.hasNode(target)) {
      graph.addNode(target);
    }

    graph.dropEdge(edges[0]);
    graph.addEdgeWithKey(edges[0], chain, target);

    return createOk(null);
  } else if (verseAtEdge === versesAtEdge[1]) {
    const verseIndex = getVerseIndex(versesAtEdge[1]);
    const target = getVerseId(verseIndex - 1);

    if (!graph.hasNode(target)) {
      graph.addNode(target);
    }

    graph.dropEdge(edges[1]);
    graph.addEdgeWithKey(edges[1], chain, target);

    return createOk(null);
  } else {
    return createErr(ChainError.VerseNotAtEdgeCannotBeLinked);
  }
}

export function growChain(
  graph: AbstractGraph,
  chain: GraphNodeId,
  until: VerseId,
  dir: 1 | -1,
) {
  const edges = graph.filterOutboundEdges(chain, (_e, _a, _s, target) =>
    isVerse(target),
  );
  const targetIndex = getVerseIndex(until);
  const verseIndicesAtEdge = edges
    .map((x) => getVerseIndex(graph.target(x)))
    .sort() as [number, number];

  // const dir = targetIndex < verseIndicesAtEdge[0] ? -1 : 1;

  const targets = (
    dir === 1
      ? Array(targetIndex - verseIndicesAtEdge[1])
      : Array(verseIndicesAtEdge[0] - targetIndex)
  )
    .fill(undefined)
    .map((_, index) => {
      return dir === 1
        ? verseIndicesAtEdge[1] + index + 1
        : verseIndicesAtEdge[0] - index - 1;
    });

  const alreadyLinked = graph.someNode((node) => {
    if (isChain(node)) {
      const range = intoChainVerseRange(graph, node).map(
        getVerseIndex,
      ) as VerseIndexRange;
      return targets.some((x) => includes(range, x));
      // return includes(range, targetIndex)
    } else {
      return false;
    }
  });

  if (alreadyLinked) {
    return createErr(ChainError.VerseBelongsToAnotherChain);
  }

  const newEdge = getVerseId(targets[targets.length - 1]!);

  if (dir === 1) {
    if (!graph.hasNode(newEdge)) {
      graph.addNode(newEdge);
    }

    graph.dropEdge(edges[1]);
    graph.addEdgeWithKey(edges[1], chain, newEdge);
  } else {
    if (!graph.hasNode(newEdge)) {
      graph.addNode(newEdge);
    }

    graph.dropEdge(edges[0]);
    graph.addEdgeWithKey(edges[0], chain, newEdge);
  }

  return createOk(targets.length);
}

const intersects = (a: VerseIndexRange, b: VerseIndexRange) =>
  b[0] >= a[0] && b[0] <= a[1];

const includes = (range: VerseIndexRange, point: VerseIndex): boolean => {
  return point >= range[0] && point <= range[1];
};
