import { useURLState } from "@muddakir/engine";

export default function useMorphModeInURL() {
  return useURLState({
    name: "morph",
    defaultValue: false,
    encode: (x) => (x ? "1" : null),
    decode: (x) => x === "1",
  });
}
