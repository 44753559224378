/** @jsxImportSource pmjsx */
import { MuddakirPlugin } from "#plugins";
import {
  AppendixAnchor,
  AppendixNote,
  AppendixSectionHeading,
} from "@muddakir/appendixes/pm/composers";
import { VersesInFolders } from "@muddakir/folders/types";
import {
  getGroupForParticipant as getM10tGroupForParticipant,
  getGroupsForVerse as getM10tGroupsForVerse,
} from "@muddakir/m10t";
import { findNotesFor, findNotesForWordsInNode } from "@muddakir/notes";
import { getVerseWordsBetweenChars, Unit } from "@muddakir/quran-db";
import { ScopeType } from "@muddakir/quran-verse-range";
import { Scribe } from "@muddakir/scribe";
import { VerseLinkAttrs } from "@muddakir/verse-anchors";
import { VerseNumber } from "@muddakir/verse-anchors/pm/blocks";
import { shouldGroup } from "@muddakir/verse-grouping";
import { VerseGroup } from "@muddakir/verse-grouping/pm/composers";
import { VerseGroupAndVerses } from "@muddakir/verse-grouping/types";
import {
  GroupWordRootFrequency,
  VerseWordRootFrequency,
} from "@muddakir/verse-wrf";
import AbstractGraph from "graphology-types";
import { scryJSX } from "pmjsx";
import { markWith, wrap, wrapWith } from "prosemirror-wrap";

export type M10tForwardedProps = {
  m10tEdit: GraphEdgeId | undefined;
};

type GroupAndVerses = VerseGroupAndVerses<
  {
    id: string;
    number: number;
    text: string;
  } & VerseLinkAttrs
>;

type Props = {
  anchor: string | undefined;
  // editable: boolean;
  groupingUnit: Unit | null | undefined;
  nodeType: Unit | null;
  plugins: MuddakirPlugin[];
  scopeType: ScopeType;
  verseWRF: VerseWordRootFrequency;
  verseGroupWRF: Record<UnitId, GroupWordRootFrequency>;
  versesInFolders: VersesInFolders;
  scribe: Scribe;
} & GraphProps &
  M10tForwardedProps;

type GraphProps = {
  graph: AbstractGraph;
  graphState: unknown;
};

interface State {
  wrfGroupCounter: number;
}

export default (props: Props, groups: GroupAndVerses[]) => {
  const nodes: JSX.Element[] = [];

  const shouldGroupVerses = shouldGroup({
    nodeType: props.nodeType,
    groupingUnit: props.groupingUnit,
    scopeType: props.scopeType,
    groups,
  });

  let state: State = { wrfGroupCounter: 0 };

  for (const [index, groupAndVerses] of groups.entries()) {
    const groupVerses = (
      <GroupVerses
        {...props}
        groupAndVerses={groupAndVerses}
        state={state}
      />
    );

    if (!shouldGroupVerses) {
      nodes.push(<pm:generic-container>{groupVerses}</pm:generic-container>);
    } else {
      nodes.push(
        <VerseGroup
          index={index}
          groupAndVerses={groupAndVerses}
        >
          {groupVerses}
        </VerseGroup>,
      );
    }
  }

  return nodes;
};

function GroupVerses({
  anchor,
  scribe,
  graph,
  graphState,
  groupAndVerses: { group, verses },
  groupingUnit,
  nodeType,
  plugins,
  verseWRF,
  verseGroupWRF,
  versesInFolders,
  m10tEdit,
}: Props & {
  groupAndVerses: GroupAndVerses;
  scribe: Scribe;
  state: State;
}) {
  const content: JSX.Node[] = [];
  const appendix: JSX.Node[] = [];
  const m10tAppendix: JSX.Node[] = [];
  const verseNotesInAppendix: JSX.Node[] = [];

  for (const verse of verses) {
    const uncommonWords = verseWRF[verse.id];

    content.push(
      <pm:verse id={verse.id}>
        {wrap(verse.text, [
          ...wrapWithVerseM10tHighlights({ graph, verse }),
          ...wrapWithVerseInlineNotes({
            graph,
            verse,
          }),

          ...(uncommonWords
            ? uncommonWords.map(({ rank, charRange }) => {
                return {
                  __range: charRange,
                  marks: [{ type: "verseWRF", attrs: { rank } }],
                };
              })
            : []),
        ])}
      </pm:verse>,
    );

    if (versesInFolders[verse.id]?.size) {
      content.push(
        <pm:verse-in-folder-emblem folders={versesInFolders[verse.id]!} />,
      );
    }

    content.push(
      <VerseNumber
        anchor={anchor}
        nodeType={nodeType}
        groupingUnit={groupingUnit}
        verse={verse}
      />,
    );

    m10tAppendix.push(
      <AppendixVerseM10tGroups
        graph={graph}
        graphState={graphState}
        verse={verse}
        m10tEdit={m10tEdit}
      />,
    );

    if (!group || group.type !== Unit.Verse) {
      verseNotesInAppendix.push(
        <AppendixNote
          plugins={plugins}
          notable={verse}
          anchor={
            <AppendixAnchor
              scribe={scribe}
              href={verse.href}
              id={verse.id}
            />
          }
          graph={graph}
        />,
      );
    }
  }

  if (scryJSX(m10tAppendix)) {
    appendix.push(
      <>
        <AppendixSectionHeading title="المتشابهات في هذه الايات" />

        <pm:appendix-table className="mushaf-appendix-m10t-group">
          {m10tAppendix}
        </pm:appendix-table>
      </>,
    );
  }

  const groupNoteInAppendix = group && group.href && (
    <AppendixNote
      plugins={plugins}
      notable={group}
      anchor={
        <AppendixAnchor
          scribe={scribe}
          href={group.href}
          id={group.id}
        />
      }
      graph={graph}
    />
  );

  if (scryJSX([...verseNotesInAppendix, groupNoteInAppendix])) {
    appendix.push(
      <>
        <AppendixSectionHeading title="الملاحظات حول هذه الايات" />
        <pm:appendix-table>
          {verseNotesInAppendix}
          {groupNoteInAppendix}
        </pm:appendix-table>
      </>,
    );
  }

  if (group !== null && verseGroupWRF[group.id]) {
    const wordCount = Object.keys(verseGroupWRF[group.id]!).length;

    appendix.push(
      <>
        <AppendixSectionHeading
          href={`${group.altHref}/words`}
          title={
            wordCount > 3
              ? `الكلمات الحصرية في هذه الايات (${wordCount})`
              : "الكلمات الحصرية في هذه الايات"
          }
        />

        <pm:appendix-table
          variant="packed"
          className="verse-word-frequency-appendix"
        >
          <GroupWordRootFrequencyAppendix wrf={verseGroupWRF[group.id]!} />
        </pm:appendix-table>
      </>,
    );
  }

  return (
    <>
      <pm:justified>{content}</pm:justified>
      {scryJSX(appendix) ? <pm:appendix>{appendix}</pm:appendix> : null}
    </>
  );
}

function GroupWordRootFrequencyAppendix({
  wrf,
}: {
  wrf: GroupWordRootFrequency;
}) {
  return (
    <>
      {Object.entries(wrf).map(([root, rootVerses]) => {
        return (
          <pm:appendix-entry>
            <pm:appendix-item-anchor>
              <pm:hyperlink
                target="_blank"
                href={`https://tafsir.app/lisan/${root}`}
              >
                <pm:text
                  text={root}
                  marks={[{ type: "strong" }]}
                />
              </pm:hyperlink>
            </pm:appendix-item-anchor>

            <pm:appendix-item-body variant="copy">
              {rootVerses.map(({ chapter, verse, word }) => (
                <>
                  <pm:text text={`${word} `} />
                  <pm:unit-anchor
                    href={`#/${chapter}?a=${verse}`}
                    contenteditable={false}
                  >
                    {verse}
                  </pm:unit-anchor>
                  <pm:text text={`\n`} />
                </>
              ))}
            </pm:appendix-item-body>
          </pm:appendix-entry>
        );
      })}
    </>
  );
}

function AppendixVerseM10tGroups({
  graph,
  graphState,
  verse,
  m10tEdit,
}: {
  verse: { id: string };
} & GraphProps &
  M10tForwardedProps) {
  const m10tGroups = getM10tGroupsForVerse(graph, verse.id);

  if (!m10tGroups.length) {
    return null;
  }

  return m10tGroups.map((group) => {
    return (
      <AppendixVerseM10tGroup
        verse={verse}
        group={group}
        showRange={m10tGroups.length > 1}
        graph={graph}
        graphState={graphState}
        m10tEdit={m10tEdit}
      />
    );
  });
}

function AppendixVerseM10tGroup({
  graph,
  graphState,
  verse,
  group,
  showRange,
  m10tEdit,
}: {
  verse: { id: string };
  group: M10tParticipation[];
  showRange: boolean;
} & GraphProps &
  M10tForwardedProps) {
  const { edge, marker } = group.find((x) => x.marker[0] === verse.id)!;
  const notes = findNotesFor(graph, graph.source(edge));
  const id = getM10tGroupForParticipant(graph, edge);
  const words = getVerseWordsBetweenChars(
    verse.id,
    marker.slice(1) as [number, number],
  );

  if (words[0] === null || words[1] === null) {
    console.error(
      `expected verse words between ${JSON.stringify(marker)}:`,
      verse.id,
      words,
    );

    return null;
  }

  return (
    <pm:appendix-item>
      <pm:appendix-item-anchor id={id}>
        <pm:unit-anchor
          href={`#/${id}`}
          contenteditable={false}
          className="m10t-appendix-group__label"
        >
          {showRange
            ? `(${verse.id}) [${words[0]! + 1}..${words[1]! + 1}]`
            : `(${verse.id})`}
        </pm:unit-anchor>
      </pm:appendix-item-anchor>

      <pm:appendix-item-body>
        <pm:m10t-slot
          edge={edge}
          m10tEdit={m10tEdit}
          fingerprint={graphState}
          anchor="hyperlink"
        />

        {notes.length > 0 && (
          <pm:note-slot
            edge={notes[0]!.edge}
            className="mushaf-appendix-m10t-group-note-editor"
          />
        )}
      </pm:appendix-item-body>
    </pm:appendix-item>
  );
}

export function wrapWithVerseInlineNotes({
  graph,
  verse,
}: {
  graph: AbstractGraph;
  verse: { id: string };
}): Wrapper[] {
  const inlineNotes = graph.hasNode(verse.id)
    ? findNotesForWordsInNode(graph, verse.id)
    : [];

  return inlineNotes.map(
    ({ edge, range }: { edge: string; range: WrappingRange }) => ({
      __range: range,
      content: [
        <pm:note-slot
          edge={edge}
          className="mushaf-verse-inline-note"
        />,
      ],
    }),
  );
}

export function wrapWithVerseM10tHighlights({
  graph,
  verse,
}: {
  graph: AbstractGraph;
  verse: { id: string };
}): Wrapper[] {
  const m10tGroups = getM10tGroupsForVerse(graph, verse.id);

  const children = m10tGroups.reduce((acc: Wrapper[], group, index) => {
    for (const { edge, marker, degree } of group) {
      if (marker[0] === verse.id) {
        acc.push(
          markWith({
            range: [marker[1], marker[2]],
            marks: [
              {
                type: "m10tTargetHighlight",
                attrs: { edge, anchor: index + 1 },
              },
            ],
          }),
        );

        acc.push(
          wrapWith({
            range: [marker[2], marker[2]],
            content: [
              <pm:m10t-target-anchor>{` +${degree} `}</pm:m10t-target-anchor>,
            ],
          }),
        );
      }
    }

    return acc;
  }, []);

  return children;
}
