/** @jsxImportSource pmjsx */

import { MuddakirPlugin } from "#plugins";
import { findNotesForNode } from "@muddakir/notes";
import { Scribe } from "@muddakir/scribe";
import AbstractGraph from "graphology-types";

export function AppendixAnchor({
  scribe,
  href,
  id,
}: {
  scribe: Scribe;
  href: string;
  id?: string;
}) {
  return (
    <pm:unit-anchor
      href={href}
      contenteditable={false}
    >
      {id && `${scribe.describe({ id })}`}
    </pm:unit-anchor>
  );
}

export class YieldSignal {}
export function AppendixNote({
  notable,
  anchor,
  graph,
  plugins,
}: {
  notable: { id: GraphNodeId };
  anchor: JSX.Element;
  graph: AbstractGraph;
  plugins: MuddakirPlugin[];
}) {
  const { id } = notable;

  if (!graph.hasNode(id)) {
    return [];
  }

  const pluginsForMe = plugins
    .filter((x) => x[0] === "appendix-note-reference")
    .map((x) => x[1]);
  const yieldSignal = new YieldSignal();

  return findNotesForNode(graph, id).map((note) => {
    const pluginContent = pluginsForMe.reduce(
      (acc: YieldSignal | JSX.Element | null, plugin) => {
        if (acc === yieldSignal) {
          return plugin(note, yieldSignal);
        } else {
          return acc;
        }
      },
      yieldSignal,
    );

    return (
      <pm:appendix-item id={note.id}>
        <pm:appendix-item-anchor>{anchor}</pm:appendix-item-anchor>

        <pm:appendix-item-body>
          <>
            <pm:note-slot
              edge={note.edge}
              className="mushaf-note-editor"
            />
            {pluginContent === yieldSignal ? null : pluginContent}
          </>
        </pm:appendix-item-body>
      </pm:appendix-item>
    );
  });
}

export function AppendixSectionHeading({
  href,
  title,
}: {
  href?: string | undefined;
  title: string;
}) {
  return (
    <pm:heading
      level={2}
      className="appendix-section-heading"
    >
      {title}

      {href && <pm:hyperlink href={href}> ← </pm:hyperlink>}
    </pm:heading>
  );
}
