import { Unit } from "@muddakir/quran-db";
import VerseGroupingMainMenuItems from "@muddakir/verse-grouping/menu";
import { FolderMainMenuItems } from "../menu";

export default function MainMenuItems({
  groupingUnitControl,
}: {
  groupingUnitControl: [Unit, (unit: Unit | null) => void];
}) {
  return (
    <>
      <FolderMainMenuItems />

      <VerseGroupingMainMenuItems group={groupingUnitControl} />
    </>
  );
}
