import { EditorState } from "prosemirror-state";

export {
  adjustRange,
  dropParticipant,
  getGroupForParticipant,
  getGroupsForVerse,
  getGroupTarget,
  getParticipatingVerses,
  getVersesInGroup,
  isM10t,
  prepareGroup,
  removeGroupIfPossible,
} from "./graph";
// export { default as groupEditorView } from "./groupEditor";
export { default as schema } from "./schema";
// export { default as slotView } from "./slot";

export const getM10tGroupsInSelection = (state: EditorState): GraphEdgeId[] => {
  const [from, to] = [state.selection.from, state.selection.to];
  const edges: GraphEdgeId[] = [];

  state.doc.nodesBetween(from, to, (node) => {
    const mark = node.marks.find(
      (x) => x.type === state.schema.marks.m10tTargetHighlight,
    );

    if (mark) {
      edges.push(mark.attrs.edge);
    }
  });

  return edges;
};
