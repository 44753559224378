import { GraphContext, PluginContext, usePreference } from "@muddakir/engine";
import useNotesInURL from "@muddakir/notes/useNotesInURL";
import { classify, getVerses } from "@muddakir/quran-db";
import { expand } from "@muddakir/quran-verse-range";
import {
  VerseTree,
  createUnitSiblingComputer,
} from "@muddakir/quran-verse-tree";
import { computeVerseHref } from "@muddakir/verse-anchors";
// import { schema } from "@muddakir/verse-editor";
import { useScribe } from "@muddakir/scribe";
import { groupVersesBy } from "@muddakir/verse-grouping";
import { groupingUnitPreference as groupingUnitPref } from "@muddakir/verse-grouping/preferences";
import React, { useContext, useMemo, useState } from "react";
import { LayoutProps, M10tTargetProps } from ".";
import { getVersesInGroup } from "../graph";
import layout from "./layout";

export const useM10tDocument = ({
  id,
  path,
  scopeType,
  editedVerse,
}: M10tTargetProps): LayoutProps & { error: string | null } => {
  const { graph, graphState } = React.useContext(GraphContext);
  const [groupingUnit] = usePreference(groupingUnitPref);
  const [error, setError] = useState<string | null>(null);
  const notes = useNotesInURL({ graph, graphState });
  const scribe = useScribe();

  const { enabled: plugins } = useContext(PluginContext);

  const m10tGroup = useMemo(
    () => getVersesInGroup(graph, id),
    [id, graphState],
  );
  const tree = React.useMemo(
    () =>
      new VerseTree((x) => {
        try {
          return x === id ? m10tGroup.map(({ id }) => id) : expand([x]);
        } catch (e) {
          console.error(e);
          setError(() => `Could not find group or verses with the id "${id}"`);
          return [];
        }
      }, createUnitSiblingComputer()),
    [pidOf(m10tGroup)],
  );

  const { doc, node } = React.useMemo(() => {
    const node = tree.find(path);

    if (!node) {
      return { doc: null, node: null };
    }

    const hrefComputer = computeVerseHref({ node });
    const m10tAttrComputer = addM10tAttributes(m10tGroup);
    const verses = getVerses(node.verses);
    const versesWithHref = verses.map((x) => ({ ...x, ...hrefComputer(x) }));
    const versesWithM10tAttributes = versesWithHref.map((x) => ({
      ...x,
      ...m10tAttrComputer(x),
    }));

    const groups = groupVersesBy({
      groupingUnit,
      node,
      versePool: versesWithM10tAttributes,
    });

    console.log("recomputing doc");
    const content = layout(
      {
        graph,
        nodeType: classify(node.id),
        groupingUnit,
        m10tGroupId: id,
        // allow editing only at the top level
        displayGroupNote: node.root === node,
        editable: node.root === node,
        editedVerse,
        editNote: notes.editNote,
        plugins,
        scopeType,
        scribe,
      },
      groups,
    );

    // const doc = schema.nodeFromJSON({
    //   type: "doc",
    //   content,
    // });

    return { node, doc: { type: "doc", content } };
  }, [tree, path, groupingUnit, graphState, editedVerse]);

  return { error, node, doc, notes, scribe };
};

const pidOf = (group: M10tParticipation[]) =>
  group.map((x) => x.marker.join(".")).join(".");

const addM10tAttributes =
  (m10tGroup: M10tParticipation[]) =>
  <T extends { id: VerseId }>(
    verse: T,
  ): { edge: GraphEdgeId; marker: VerseMarker } | undefined => {
    for (const participant of m10tGroup) {
      if (participant.id === verse.id) {
        return { edge: participant.edge, marker: participant.marker };
      }
    }

    return;
  };
