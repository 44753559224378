/// Wrap parts of a text with nodes that can contain additional content.
///
/// (text: String, wrappers: Wrapper[]): NodeSpec[]
/// where:
///   Wrapper: {
///     range: [number, number],
///     dir: ?number,
///     ...NodeSpec
///   }
///

type SerializedMark = { type: string; attrs?: object };

interface NodeSpec {
  attrs?: object | undefined;
  content?: NodeSpec[];
  type?: string;
  marks?: MarkSpec[];
}

interface MarkSpec {
  type: string;
  attrs?: object | undefined;
}

interface Hole {
  range: WrappingRange;
  content: unknown;
}

type ContentWithRange = { range: WrappingRange; content: NodeSpec[] };

interface HierarchicalNode {
  node: Wrapper & NodeSpec;
  children: HierarchicalNode[];
}

/// Behavior is undefined if ranges are invalid.
export function wrap(text: string, wrappers: Wrapper[]): JSX.Node {
  if (wrappers.some((x) => !x.__range)) {
    console.warn("cannot wrap with no range:", wrappers);
    return text;
  }

  const root: HierarchicalNode = {
    node: { __range: [0, text.length], content: [] },
    children: computeLayout(wrappers),
  };

  return reify(text)(root).content as JSX.Node;
}

export function wrapWith({
  range,
  content,
}: {
  range: WrappingRange;
  content: JSX.Element[];
}): Wrapper {
  return { __range: range, content };
}

export function markWith({
  range,
  marks,
}: {
  range: WrappingRange;
  marks: SerializedMark[];
}): Wrapper {
  return { __range: range, marks };
}

const reify = (text: string) =>
  function reify(
    { children, node }: HierarchicalNode,
    parentMarks: MarkSpec[] = [],
  ): ContentWithRange {
    const {
      content = [],
      marks = [],
      __dir = 1,
      type = "partition",
      attrs,
      __force = false,
    } = node;
    const holes: ContentWithRange[] = [];
    const range = node.__range;

    for (const child of children) {
      holes.push(reify(child, parentMarks.concat(marks)));
    }

    const [filled, size] = fillHoles(
      text,
      range,
      holes,
      parentMarks.concat(marks),
    );
    const filledAndWrapped: NodeSpec[] =
      content.length || __force === true || range[1] === range[0]
        ? [
            {
              attrs,
              type,
              content: [
                ...(__dir === -1 ? content : []),
                ...filled,
                ...(__dir > -1 ? content : []),
              ],
            },
          ]
        : filled;

    return {
      range: [range[0], range[0] + size],
      content: filledAndWrapped,
    };
  };

export const computeLayout = (nodes: Wrapper[]) => {
  interface NodePlacement {
    range: WrappingRange;
    node: Wrapper;
    parent: WrappingRange;
  }

  const ROOT: [number, number] = [NaN, NaN];
  const hierarchy: NodePlacement[] = [];
  const ranges = nodes.map((x) => x.__range);
  const place = (
    range: WrappingRange,
    candidates: WrappingRange[],
  ): WrappingRange => {
    const candidate = candidates.shift();

    if (!candidate) {
      return ROOT;
    }
    // among two identical ranges, the latter ends up contained in the former:
    else if (
      hierarchy.some(
        (other) => other.range === candidate && other.parent === range,
      )
    ) {
      return place(range, candidates);
    } else {
      return candidate;
    }
  };

  for (const [index, range] of ranges.entries()) {
    const intersecting = ranges.filter(
      (other) => other !== range && intersects(other, range),
    );

    const closest = intersecting.sort((a, b) =>
      range[0] - a[0] > range[0] - b[0] ? 1 : -1,
    );

    hierarchy.push({
      range,
      node: nodes[index]!,
      parent: place(range, closest),
    });
  }

  hierarchy.sort((a, b) => (a.range[0] > b.range[0] ? 1 : -1));

  const treeify = ({ node, range }: NodePlacement): HierarchicalNode => ({
    node,
    children: hierarchy.filter((x) => x.parent === range).map(treeify),
  });

  return hierarchy.filter((x) => x.parent === ROOT).map(treeify);
};

// Given a text, a range within that text, and a list of "holes" within that
// range, produce a structure where the content of those holes are placed at
// the location they specify, and fill any gaps with the remaining text.
//
//     "hello world"
//        ^^^   ^^
//        ^     second hole, content = "Y"
//        first hole, content = "X"
//
// Will produce (a structure of):
//
//     "heX woYd"
//
// Holes may extend beyond the initial range, but the function will guarantee
// that a portion of at least the initial range is consumed from the text. The
// first return value is the size of the filled content.
//
// (
//   text: string,
//   range: [number,number],
//   holes: {range:[number,number], content: any}[],
//   marks: ?MarkSpec[]
// ): [NodeSpec[], number]
export const fillHoles = (
  text: string,
  range: WrappingRange,
  holes: Hole[],
  marks: MarkSpec[],
): [NodeSpec[], number] => {
  const filled = [];
  let cursor = range[0];

  for (const hole of holes) {
    // fill gap between holes
    if (hole.range[0] > cursor) {
      filled.push(textNode(text.slice(cursor, hole.range[0]), marks));
    }

    if (Array.isArray(hole.content)) {
      filled.push(...hole.content);
    } else {
      filled.push(hole.content);
    }

    cursor = hole.range[1];
  }

  // fill gap at the end
  if (cursor < range[1]) {
    filled.push(textNode(text.slice(cursor, range[1]), marks));
    cursor = range[1];
  }

  return [
    filled.filter((x) => x.type !== "text" || (x.type === "text" && x.text)),
    cursor - range[0],
  ];
};

const intersects = (a: WrappingRange, b: WrappingRange) =>
  b[0] >= a[0] && b[0] <= a[1];
const textNode = (text: string, marks: MarkSpec[]) => {
  if (marks) {
    return { type: "text", text, marks };
  } else {
    return { type: "text", text };
  }
};
