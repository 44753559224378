import Layout from "@muddakir/layout";
import { SearchPalette } from "@muddakir/search/react";
import * as style from "./index.module.css";

export function LandingPage() {
  return (
    <Layout>
      <div className={style.searchBox}>
        <SearchPalette onClose={() => {}} />
      </div>
    </Layout>
  );
}
