/** @jsxImportSource pmjsx */

import { MuddakirPlugin } from "#plugins";
import {
  AppendixAnchor,
  AppendixNote,
  AppendixSectionHeading,
} from "@muddakir/appendixes/pm/composers";
import { t } from "@muddakir/engine";
import { findNotesFor, hasNote } from "@muddakir/notes";
import { AssignEditedNoteCallback } from "@muddakir/notes/pm";
import { getVerseText, Unit } from "@muddakir/quran-db";
import { ScopeType } from "@muddakir/quran-verse-range";
import { Scribe } from "@muddakir/scribe";
import { VerseLinkAttrs } from "@muddakir/verse-anchors";
import { VerseNumber } from "@muddakir/verse-anchors/pm/blocks";
import { shouldGroup } from "@muddakir/verse-grouping";
import { VerseGroup } from "@muddakir/verse-grouping/pm/composers";
import { VerseGroupAndVerses } from "@muddakir/verse-grouping/types";
import AbstractGraph from "graphology-types";
import { scryJSX } from "pmjsx";
import { VerseM10tAttrs } from ".";

type GroupAndVerses = VerseGroupAndVerses<
  Verse & VerseLinkAttrs & VerseM10tAttrs
>;

export default function render(
  {
    displayGroupNote,
    editable,
    graph,
    groupingUnit,
    m10tGroupId,
    nodeType,
    plugins,
    scopeType,
    editNote,
    editedVerse,
    scribe,
  }: {
    displayGroupNote: boolean;
    editable: boolean;
    editedVerse: GraphEdgeId | undefined;
    editNote: AssignEditedNoteCallback;
    graph: AbstractGraph;
    groupingUnit: Unit | null | undefined;
    m10tGroupId: GraphNodeId;
    nodeType: Unit | null;
    plugins: MuddakirPlugin[];
    scopeType: ScopeType;
    scribe: Scribe;
  },
  groupsAndVerses: GroupAndVerses[],
) {
  const verseNotes = (
    <M10tVerseNotes
      graph={graph}
      groupsAndVerses={groupsAndVerses}
      plugins={plugins}
      scribe={scribe}
    />
  );

  const hasVerseNotes = scryJSX(verseNotes);

  const shouldGroupVerses = shouldGroup({
    nodeType,
    groupingUnit,
    scopeType,
    groups: groupsAndVerses,
  });

  const Container = shouldGroupVerses
    ? ({
        children,
        index,
        groupAndVerses,
      }: {
        index: number;
        groupAndVerses: GroupAndVerses;
        children: JSX.Node;
      }) => (
        <VerseGroup
          index={index}
          groupAndVerses={groupAndVerses}
        >
          {children}
        </VerseGroup>
      )
    : ({ children }: { children: JSX.Node }) => children;

  return (
    <>
      <pm:unit id={m10tGroupId}>
        <>
          {groupsAndVerses.map((groupAndVerses, index) => (
            <Container
              index={index}
              groupAndVerses={groupAndVerses}
            >
              {groupAndVerses.verses.map((verse) => {
                const verseNode =
                  editedVerse === verse.edge! ? (
                    <pm:verse-slice-editor
                      className={"mushaf-m10t-verse-slice"}
                      anchor={null}
                      edge={verse.edge!}
                      marker={verse.marker!}
                    >
                      {getVerseText(verse.marker![0])}
                    </pm:verse-slice-editor>
                  ) : (
                    <pm:verse-slice edge={verse.edge!}>
                      <pm:verse id={verse.marker![0]}>
                        {getVerseText(verse.marker![0]).slice(
                          ...(verse.marker!.slice(1) as [number, number]),
                        )}
                      </pm:verse>
                    </pm:verse-slice>
                  );

                return (
                  <>
                    {hasNote(graph, verse.edge!) ? (
                      <pm:generic-container className="mushaf-verse-and-inline-content">
                        {verseNode}

                        <pm:note
                          className="mushaf-verse-inline-note"
                          edge={verse.edge!}
                        />
                      </pm:generic-container>
                    ) : (
                      verseNode
                    )}

                    <VerseNumber
                      anchor={undefined}
                      verse={verse}
                      nodeType={nodeType}
                      groupingUnit={groupingUnit}
                    />
                  </>
                );
              })}
            </Container>
          ))}
        </>
      </pm:unit>

      {displayGroupNote && (
        <pm:justified>
          {findNotesFor(graph, m10tGroupId).map((note) => (
            <pm:note-slot
              bordered={false}
              edge={note.edge}
            />
          ))}
        </pm:justified>
      )}

      {hasVerseNotes && (
        <>
          <AppendixSectionHeading title={t("ملاحظات حول الايات في المجموعة")} />
          <pm:appendix-table>{verseNotes}</pm:appendix-table>
        </>
      )}
    </>
  );
}

function M10tVerseNotes({
  groupsAndVerses,
  graph,
  plugins,
  scribe,
}: {
  groupsAndVerses: GroupAndVerses[];
  graph: AbstractGraph;
  plugins: MuddakirPlugin[];
  scribe: Scribe;
}) {
  return groupsAndVerses.map(({ verses }) =>
    verses.map((verse) => (
      <>
        <AppendixNote
          plugins={plugins}
          graph={graph}
          notable={verse}
          anchor={
            <AppendixAnchor
              scribe={scribe}
              href={verse.href}
              id={verse.id}
            />
          }
        />

        <AppendixVerseWordNotes
          scribe={scribe}
          graph={graph}
          verse={verse}
        />
      </>
    )),
  );
}

function AppendixVerseWordNotes({
  scribe,
  graph,
  verse,
}: {
  scribe: Scribe;
  graph: AbstractGraph;
  verse: { id: string; href: string; text: string };
}) {
  const notes =
    (graph.hasNode(verse.id) &&
      findNotesFor(graph, verse.id).filter((x) => x.range)) ||
    [];

  return notes.map((note) => (
    <pm:appendix-item>
      <pm:appendix-item-anchor>
        <AppendixAnchor
          scribe={scribe}
          {...verse}
        />{" "}
        <pm:text
          text={verse.text.slice(...note.range!)}
          marks={[{ type: "strong" }]}
        />
      </pm:appendix-item-anchor>

      <pm:appendix-item-body>
        <pm:note
          edge={note.edge}
          className="mushaf-note-editor"
        />
      </pm:appendix-item-body>
    </pm:appendix-item>
  ));
}
