import { useContext, useEffect } from "react";
import { ProseMirrorContext } from "react-prosemirror";
import { verseCursorPlugin } from "../plugin";

export function ProseMirrorVerseJumping() {
  const { register } = useContext(ProseMirrorContext);

  useEffect(() => {
    return register({
      plugins: [verseCursorPlugin()],
    });
  }, []);

  return null;
}
