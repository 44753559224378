import { GraphContext, usePreference } from "@muddakir/engine";
import { Unit } from "@muddakir/quran-db";
import { groupingUnitPreference } from "@muddakir/verse-grouping/preferences";
import { useCallback, useContext, useMemo } from "react";

export function useFolderGroupingUnitState({ id }: { id: GraphNodeId }) {
  const { graph, graphState } = useContext(GraphContext);
  const [defaultGroupingUnit] = usePreference(groupingUnitPreference);

  const groupingUnit = useMemo(() => {
    return graph.getNodeAttribute(id, "groupingUnit") || defaultGroupingUnit;
  }, [defaultGroupingUnit, graphState]);

  const setGroupingUnit = useCallback(
    (unit: Unit | null) => {
      if (unit === null) {
        graph.removeNodeAttribute(id, "groupingUnit");
      } else {
        graph.setNodeAttribute(id, "groupingUnit", unit);
      }
    },
    [graph],
  );

  return [groupingUnit, setGroupingUnit];
}
