import { MainMenuItem, usePreference } from "@muddakir/engine";
import { m10tMode as m10tModePref } from "./preferences";

export default function M10tMainMenuItems({
  onInsert,
}: {
  onInsert: () => void;
}) {
  const [m10tMode, setM10tMode] = usePreference(m10tModePref);

  return (
    <>
      <MainMenuItem
        type="checkbox"
        path={["View", "Similar Verses"]}
        onTrigger={() => {
          setM10tMode(m10tMode === "inline" ? "none" : "inline");
          return true;
        }}
        value={m10tMode === "inline"}
      />

      <MainMenuItem
        path={["Edit", "Link to Similar Verse"]}
        onTrigger={() => {
          onInsert();
          // TODO: check if command was actually applied
          return true;
        }}
        keyBinding="m"
      />
    </>
  );
}
