import cx from "classnames";
import { SchemaSpec } from "prosemirror-model";

const schema: SchemaSpec = {
  marks: {},
  nodes: {
    appendix: {
      group: "block",
      content: "block*",
      toDOM: () => ["div", { class: "appendix" }, 0],
      selectable: false,
      atom: true,
    },

    appendixEntry: {
      group: "block",
      content: "inline*",
      toDOM: () => ["div", { class: "appendix-entry" }, 0],
    },

    appendixTable: {
      attrs: {
        className: { default: null },
        variant: { default: "spaced" },
      } as unknown as PMAttributes<{
        className?: string | undefined;
        variant?: "spaced" | "packed" | undefined;
      }>,
      group: "block",
      content: "appendixItem*",
      editable: false,
      toDOM: ({ attrs }: any) => [
        "table",
        {
          contenteditable: false,
          class: cx("appendix-table", attrs.className, {
            "appendix-table--spaced": attrs.variant === "spaced",
            "appendix-table--packed": attrs.variant === "packed",
          }),
        },
        0,
      ],
    },

    appendixItem: {
      attrs: {
        id: { default: null },
      } as unknown as PMAttributes<{ id?: string | undefined }>,
      inline: true,
      content: "appendixItemAnchor appendixItemBody",
      toDOM: () => ["tr", 0],
    },

    appendixItemAnchor: {
      attrs: {
        id: { default: null },
      } as unknown as PMAttributes<{
        id?: string | undefined;
      }>,
      inline: true,
      content: "inline*",
      toDOM: () => ["td", { class: "appendix-table__item-anchor" }, 0],
    },

    appendixItemBody: {
      attrs: {
        variant: { default: null },
      } as unknown as PMAttributes<{
        variant?: "copy" | undefined;
      }>,
      inline: true,
      content: "inline*",
      toDOM: ({ attrs }: any) => [
        "td",
        {
          class: cx("appendix-table__item-body", {
            "appendix-table__item-body--copy": attrs.variant === "copy",
          }),
        },
        0,
      ],
    },

    appendixItemReference: {
      attrs: {
        href: {},
      } as unknown as PMAttributes<{
        href: string;
      }>,
      inline: true,
      content: "inline*",
      toDOM: ({ attrs }: any) => [
        "a",
        {
          href: attrs.href,
          target: "_blank",
          class: "appendix-item-reference",
        },
        0,
      ],
    },
  },
};

export default schema;
