import { GraphContext, usePreference, useURLState } from "@muddakir/engine";
import React from "react";
import { prepareGroup, removeGroupIfPossible } from "./graph";
import { m10tMode as m10tModePref } from "./preferences";
import { m10t as m10tQueryParam } from "./queryParams";

export default function useM10tInURL() {
  const [m10tEdit, setM10tEdit] = useURLState(m10tQueryParam);
  const [m10tMode] = usePreference(m10tModePref);
  const { graph } = React.useContext(GraphContext);

  const createM10tGroup = (marker: VerseMarker) => {
    setM10tEdit(prepareGroup(graph, marker));
  };

  const removeM10tGroups = (groups: GraphEdgeId[]) => {
    const removed = [];

    for (const edge of groups) {
      if (removeGroupIfPossible(graph, edge)) {
        removed.push(edge);
      }
    }

    if (m10tEdit !== undefined && groups.includes(m10tEdit)) {
      setM10tEdit(null);
    }
  };

  return {
    m10tMode: m10tMode! as "none" | "inline" | "suffix",
    m10tEdit,
    setM10tEdit,
    createM10tGroup,
    removeM10tGroups,
  };
}
