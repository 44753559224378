import { lazy } from "react";

export default [
  {
    path: "/search",
    queryParams: {
      query: {},
    },
    component: lazy(() => import("./console")),
  },
];
