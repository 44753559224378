import { MuddakirPlugin } from "#plugins";
import { t } from "@muddakir/engine";
import {
  classify,
  getAllUnits,
  getChapterName,
  getHizbNumber,
  getJuzNumber,
  getPageNumber,
  getQuarterNumber,
  getVerseText,
  isChapter,
  isHizb,
  isJuz,
  isPage,
  isQuarter,
  isVerse,
  Unit,
} from "@muddakir/quran-db";
import AbstractGraph from "graphology-types";
import { unreachable } from "typescript-util";

export const QuranPlugins: MuddakirPlugin[] = [
  [
    "search.index-documents",
    async (_graph: AbstractGraph) => {
      return getAllUnits()
        .map(([unit, id]) => {
          switch (unit) {
            case Unit.Chapter: {
              return {
                id,
                title: getChapterName(id)!,
              };
            }
            case Unit.Verse: {
              return {
                id,
                text: getVerseText(id),
              };
            }
            default: {
              return { id };
            }
          }
        })
        .filter((x) => x !== null);
    },
  ],

  [
    "scribe",
    {
      describe(_graph, { id }, lod) {
        const choose = (texts: Record<number, () => string | undefined>) => {
          let level = lod;

          while (level >= 0) {
            if (typeof texts[level] === "function") {
              return texts[level]!();
            }

            level -= 1;
          }

          return undefined;
        };

        if (isChapter(id)) {
          return choose({
            0: () => "سورة",
            1: () => getChapterName(id),
            2: () => `سورة ${getChapterName(id)}`,
          });
        } else if (isPage(id)) {
          return choose({
            0: () => "الصفحة",
            1: () => `الصفحة (${getPageNumber(id)})`,
          });
        } else if (isJuz(id)) {
          return choose({
            0: () => "الجزء",
            1: () => `الجزء (${getJuzNumber(id)})`,
          });
        } else if (isQuarter(id)) {
          return choose({
            0: () => "الربع",
            1: () => `الربع (${getQuarterNumber(id)})`,
          });
        } else if (isHizb(id)) {
          return choose({
            0: () => "الحزب",
            1: () => `الحزب (${getHizbNumber(id)})`,
          });
        } else if (isVerse(id)) {
          return choose({
            0: () => id,
            1: () => `(${id})`,
          });
        } else {
          return undefined;
        }
      },

      describeRoot(_graph, { id, verses }) {
        if (id.split(",").every(isChapter)) {
          const chapters = id.split(",");

          return [
            `(${chapters.length})`,
            "سور ما بين",
            `${getChapterName(firstOf(chapters)!)}`,
            "و",
            `${getChapterName(lastOf(chapters)!)}`,
          ].join(" ");
        } else if (id.split(",").every(isPage)) {
          const items = id.split(",").sort();

          return [
            `(${items.length})`,
            "صفحات ما بين",
            `(${getPageNumber(firstOf(items)!)})`,
            "و",
            `(${getPageNumber(lastOf(items)!)})`,
          ].join(" ");
        } else if (id.split(",").every(isQuarter)) {
          const items = id.split(",").sort();

          return [
            `(${items.length})`,
            "ارباع ما بين",
            `(${getQuarterNumber(firstOf(items)!)})`,
            "و",
            `(${getQuarterNumber(lastOf(items)!)})`,
          ].join(" ");
        } else if (id.split(",").every(isHizb)) {
          const items = id.split(",").sort();

          return [
            `(${items.length})`,
            "احزاب ما بين",
            `(${getHizbNumber(firstOf(items)!)})`,
            "و",
            `(${getHizbNumber(lastOf(items)!)})`,
          ].join(" ");
        } else if (id.split(",").every(isJuz)) {
          const items = id.split(",").sort();

          // TODO: what if items.length === 1?
          return [
            `(${items.length})`,
            "اجزاء ما بين",
            `(${getJuzNumber(firstOf(items)!)})`,
            "و",
            `(${getJuzNumber(lastOf(items)!)})`,
          ].join(" ");
        } else if (id.split(",").every(isVerse)) {
          const firstVerse = verses[0];
          const lastVerse = verses[verses.length - 1];

          return [
            verses.length,
            "اية ما بين",
            `(${firstVerse})`,
            "و",
            `(${lastVerse})`,
          ].join(" ");
        } else {
          return undefined;
        }
      },

      describeClass({ id }) {
        const unit = classify(id);
        switch (unit) {
          case Unit.Chapter: {
            return t("Chapter", "سورة");
          }
          case Unit.Hizb: {
            return t("Hizb", "حزب");
          }
          case Unit.Juz: {
            return t("Juzu", "جزء");
          }
          case Unit.Page: {
            return t("Page", "صفحة");
          }
          case Unit.Quarter: {
            return t("Quarter", "ربع");
          }
          case Unit.Verse: {
            return t("Verse", "اية");
          }
          case Unit.Custom:
          case null: {
            return undefined;
          }

          default: {
            unreachable(unit);
          }
        }

        return undefined;
      },
    },
  ],
  [
    "search.console-entry",
    (_graph, _scribe, document) => {
      const unit = classify(document.id);

      switch (unit) {
        case Unit.Verse: {
          return { displayForm: document.text! };
        }
        default: {
          return undefined;
        }
      }
    },
  ],
];

const firstOf = <T>(x: T[]) => x[0];
const lastOf = <T>(x: T[]) => x[x.length - 1];
