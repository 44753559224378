import React from "react";

export default [
  {
    name: "wird",
    path: "/wird",
    queryParams: {
      ce: {},
      cycle: {},
      cycleData: {},
    },
    component: React.lazy(() => import("./Wird")),
  },
];
