/** @jsxImportSource pmjsx */

import { type MuddakirPlugin } from "#plugins";
import { YieldSignal } from "@muddakir/appendixes/types";
import { books } from "./data";
import { renderDisplayName } from "./renderDisplayName";

export const EmbedsPlugins: MuddakirPlugin[] = [
  [
    "appendix-note-reference",
    (note: NoteForNode, yieldSignal: YieldSignal) => {
      if (note.origin && note.origin[0] === "book") {
        const book = books.find((x) => x.id === note.origin![1].id)!;

        return (
          <pm:appendix-item-reference
            href={`${book.insecure ? "http" : "https"}://${note.origin[1].url}`}
          >
            {renderDisplayName(book)}
          </pm:appendix-item-reference>
        );
      }

      return yieldSignal;
    },
  ],
];
