import { MainMenuItem, usePreference } from "@muddakir/engine";
import { sidebarEnabledPreference } from "@muddakir/layout";

export function FolderMainMenuItems() {
  const [sidebarEnabled, toggleSidebar] = usePreference(
    sidebarEnabledPreference,
  );

  return (
    <MainMenuItem
      path={["View", "Sidebar"]}
      onTrigger={() => {
        toggleSidebar(!sidebarEnabled);
        return true;
      }}
      value={!!sidebarEnabled}
      type="checkbox"
      keyBinding="ctrl+alt+s"
    />
  );
}
