import { Node } from "prosemirror-model";
import { EditorView, NodeView } from "prosemirror-view";

type VerseInFolderEmblemAttrs = { folders: Set<{ id: string; name: string }> };
export class VerseInFolderEmblem implements NodeView {
  dom;
  update;
  destroy;
  stopEvent;

  constructor(node: Node, _view: EditorView, _getPos: Function) {
    const dom = document.createElement("span");

    this.dom = dom;
    this.update = (other: Node) => other.sameMarkup(node as unknown as Node);
    this.destroy = () => {};

    const icon = document.createElement("span");
    const attrs = node.attrs as VerseInFolderEmblemAttrs;
    dom.className = "verse-in-folder-emblem";
    icon.className = "gg-folder";
    icon.innerText = `${attrs.folders.size}`;
    dom.appendChild(icon);

    let tooltip: HTMLElement | null = null;
    let visible = false;

    const getTooltip = () => {
      if (!tooltip) {
        tooltip = document.createElement("div");
        tooltip.className = "verse-in-folder-emblem-widget";
        let buf = "";
        for (const folder of attrs.folders.values()) {
          buf += `<li><a href="#/${folder.id}">${folder.name}</a></li>`;
        }
        tooltip.innerHTML = buf;
      }

      return tooltip;
    };

    const render = () => {
      tooltip = getTooltip();

      if (visible) {
        dom.appendChild(tooltip);
      } else {
        tooltip.remove();
      }
    };

    this.stopEvent = (e: Event) => {
      if (
        e.type === "mousedown" &&
        e.target &&
        tooltip &&
        tooltip.contains(e.target as HTMLElement)
      ) {
        return true;
      }

      if (e.type === "mousedown") {
        visible = !visible;
        render();
      }

      return true;
    };
  }
}
