import classNames from "classnames";
import { DOMOutputSpec, MarkSpec } from "prosemirror-model";
import { WordFrequency } from ".";

export default {
  marks: {
    verseWRF: {
      attrs: {
        rank: {},
      } as unknown as PMAttributes<{ rank: WordFrequency }>,
      toDOM: ({ attrs }: any): DOMOutputSpec => [
        "span",
        {
          class: classNames(`word-ranking`, {
            "verse-word-frequency-ranking--exclusive":
              attrs.rank === WordFrequency.Exclusive,
            "verse-word-frequency-ranking--exclusive-to-chapter":
              attrs.rank === WordFrequency.ExclusiveToChapter,
          }),
        },
        0,
      ],
    } as MarkSpec,
  },
  nodes: {},
};
