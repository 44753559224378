import { coalesce, getMarkersInSelection } from "@muddakir/verse-markers";
import AbstractGraph from "graphology-types";
import { keymap } from "prosemirror-keymap";
import { Node } from "prosemirror-model";
import { EditorState, PluginKey } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import { useContext, useEffect, useLayoutEffect } from "react";
import {
  ProseMirrorContext,
  createStateContainerPlugin,
} from "react-prosemirror";
import { adjustRange, getM10tGroupsInSelection } from "./index";
import schema, { M10tSlotNode } from "./schema";
import m10tSlotView, { M10tSlotViewProps } from "./slot";

export interface EditorProps {
  graph: AbstractGraph;
  m10tEdit: GraphEdgeId | undefined;
  m10tMode: "none" | "inline" | "suffix";
  onCreateM10tGroup: (marker: VerseMarker) => void;
  onRemoveM10tGroups: (groups: GraphEdgeId[]) => void;
  setM10tEdit: (nextValue: GraphEdgeId | null) => void;

  note: GraphEdgeId | undefined;
  editNote: (nextValue: GraphEdgeId | null) => void;

  verseMarkers: VerseMarker[];
  setVerseMarkers: (value: VerseMarker[]) => void;
}

// TODO: return false when m10t group is not being edited
export function stopEditingM10tGroup({
  setM10tEdit,
}: {
  setM10tEdit: (nextValue: GraphEdgeId | null) => void;
}) {
  setM10tEdit(null);
}

export function stopEditingM10tGroupAtCursor(
  state: EditorState,
  { m10tEdit, setM10tEdit }: EditorProps,
) {
  const edges = getM10tGroupsInSelection(state);

  if (edges.length === 1 && edges[0] === m10tEdit) {
    setM10tEdit(null);
    return true;
  }

  return false;
}

export function removeSelectedM10tGroup(
  state: EditorState,
  { onRemoveM10tGroups, setVerseMarkers, verseMarkers }: EditorProps,
) {
  const edges = getM10tGroupsInSelection(state);
  const markers = getMarkersInSelection(state);

  if (edges.length) {
    onRemoveM10tGroups(edges);

    setVerseMarkers(verseMarkers.filter((x) => !markers.includes(x)));
    return true;
  }

  return false;
}

export function expandM10tGroupAtSelection(
  state: EditorState,
  { graph, setVerseMarkers, verseMarkers }: EditorProps,
) {
  const edges = getM10tGroupsInSelection(state);

  // TODO: scan for edges across verse markers not the selection
  if (edges.length === 1 && verseMarkers.length >= 2) {
    const expanded = coalesce(verseMarkers);

    if (expanded.length === 1) {
      adjustRange(graph, edges[0]!, [expanded[0]![1], expanded[0]![2]]);
      setVerseMarkers([]);

      return true;
    }
  } else if (edges.length === 1 && verseMarkers.length === 1) {
    adjustRange(graph, edges[0]!, [verseMarkers[0]![1], verseMarkers[0]![2]]);
    setVerseMarkers([]);
    return true;
  }

  return false;
}

export function createM10tGroupAtSelection(
  state: EditorState,
  {
    onCreateM10tGroup,
    setM10tEdit,
    setVerseMarkers,
    verseMarkers,
  }: EditorProps,
) {
  const selected = getM10tGroupsInSelection(state);

  if (selected.length === 1) {
    setM10tEdit(selected[0]!);
    return true;
  }

  const marked = getMarkersInSelection(state);

  if (marked.length === 1) {
    onCreateM10tGroup(marked[0]!);

    setVerseMarkers(verseMarkers.filter((x) => !marked.includes(x)));
    return true;
  }

  return false;
}

export const m10tExtension = {
  schema,
  commands: {
    createM10tGroupAtSelection,
    expandM10tGroupAtSelection,
    stopEditingM10tGroup,
    stopEditingM10tGroupAtCursor,
    removeSelectedM10tGroup,
  },
  plugins: (binding: PluginKey<EditorProps>) => [
    keymap({
      Delete: (state) => {
        const props = binding.getState(state)!;
        if (props.m10tMode !== "none") {
          return removeSelectedM10tGroup(state, props);
        }

        return false;
      },
      m: (state) => {
        const props = binding.getState(state)!;
        if (props.m10tMode !== "none") {
          if (!expandM10tGroupAtSelection(state, props)) {
            return createM10tGroupAtSelection(state, props);
          }
        }

        return false;
      },

      Escape: (state) => {
        const props = binding.getState(state)!;
        if (props.m10tMode !== "none") {
          return stopEditingM10tGroupAtCursor(state, props);
        }

        return false;
      },
    }),
  ],

  nodeViews: <T extends M10tSlotViewProps>(binding: PluginKey<T>) => ({
    m10tSlot(node: Node, view: EditorView, getPos: Function) {
      return new m10tSlotView(node as M10tSlotNode, view, getPos, binding, {
        onDismiss: () => {
          stopEditingM10tGroup(binding.getState(view.state)!);
        },
      });
    },
  }),
};

export default m10tExtension;

export function ProseMirrorM10t(
  props: EditorProps & { binding?: PluginKey<EditorProps> | undefined },
) {
  const { register, view } = useContext(ProseMirrorContext);
  const [propsPlugin, binding] = createStateContainerPlugin<EditorProps>(
    props,
    props.binding,
  );

  useEffect(() => {
    return register({
      // @ts-expect-error
      schema,
      plugins: [propsPlugin, ...m10tExtension.plugins(binding)],
      nodeViews: m10tExtension.nodeViews(binding),
    });
  }, []);

  useLayoutEffect(() => {
    if (view) {
      view.dispatch(view.state.tr.setMeta(binding, props));
    }
  }, [...Object.values(props)]);

  return null;
}
