import { GraphContext, MainMenuItem } from "@muddakir/engine";
import { subscribeVia } from "graphology-subscribe";
import {
  GraphTransaction,
  GraphTransactionEvents,
  redo,
  undo,
} from "graphology-tx";
import { useCallback, useContext, useEffect, useRef } from "react";

export function GraphHistory() {
  const { graph, graphState } = useContext(GraphContext);
  const applied = useRef<GraphTransaction[]>([]);
  const undone = useRef<GraphTransaction[]>([]);

  useEffect(
    () =>
      subscribeVia<GraphTransactionEvents>(
        graph,
        "transactionApplied",
        (tx) => {
          applied.current.push(tx);
        },
      ),
    [graph],
  );

  useEffect(() => {
    console.log("graph changed; resetting history");

    applied.current.splice(0);
    undone.current.splice(0);
  }, [graph]);

  const undoLastChange = useCallback(() => {
    if (applied.current.length) {
      const entry = applied.current.pop()!;
      undone.current.push(entry);
      undo(graph, entry);
      return true;
    }

    return false;
  }, [graph, graphState]);

  const redoLastChange = useCallback(() => {
    if (undone.current.length) {
      const entry = undone.current.pop()!;
      applied.current.push(entry);
      redo(graph, entry);
      return true;
    }

    return false;
  }, [graph, graphState]);

  return (
    <>
      <MainMenuItem
        path={["Edit", "Undo"]}
        onTrigger={undoLastChange}
        disabled={applied.current.length === 0}
        keyBinding="Ctrl+Z"
      />

      <MainMenuItem
        path={["Edit", "Redo"]}
        onTrigger={redoLastChange}
        disabled={undone.current.length === 0}
        keyBinding="Ctrl+Y"
      />
    </>
  );
}
