import { getVerseChapterId, getVerseWord } from "@muddakir/quran-db";
import { VerseGroupAndVerses } from "@muddakir/verse-grouping/types";
import { useEffect, useMemo, useState } from "react";
import {
  findUncommonWordsIn,
  GroupWordRootFrequency,
  VerseWordRootFrequency,
} from ".";

export const useVerseWRF = ({
  verses,
  context,
}: {
  verses: VerseId[];
  context: unknown;
}): VerseWordRootFrequency => {
  const [verseWRF, setVerseWRF] = useState<VerseWordRootFrequency>({});

  useEffect(() => {
    findUncommonWordsIn(verses).then((ranking) => {
      setVerseWRF(() => ranking);
    });
  }, [context]);

  return verseWRF;
};

export const aggregateVerseWRFByGroup = ({
  groupsAndVerses,
  verseWRF,
}: {
  groupsAndVerses: VerseGroupAndVerses<{ id: string }>[];
  verseWRF: any;
}) => {
  const set: Record<string, GroupWordRootFrequency> = {};

  for (const { group, verses } of groupsAndVerses) {
    if (group === null) {
      continue;
    }

    let uncommonWordsInGroupCount = 0;

    const uncommonWordsInGroup: GroupWordRootFrequency = verses.reduce(
      (acc: GroupWordRootFrequency, { id: verseId }) => {
        if (!verseWRF[verseId]) {
          return acc;
        }

        const verseWordFrequencies = verseWRF[verseId]!;

        for (const { index: wordIndex, root } of verseWordFrequencies) {
          acc[root] ||= [];
          acc[root]!.push({
            verse: verseId,
            wordIndex,
            word: getVerseWord(verseId, wordIndex),
            chapter: getVerseChapterId(verseId),
          });

          uncommonWordsInGroupCount += 1;
        }

        return acc;
      },
      {},
    );

    if (uncommonWordsInGroupCount > 0) {
      set[group.id] = uncommonWordsInGroup;
    }
  }

  return set;
};

export const useVersesWRF = <T extends { id: string } | string>({
  context,
  verses,
  verseWRF,
}: {
  context: unknown;
  verses: T[];
  verseWRF: any;
}) => {
  return useMemo<GroupWordRootFrequency | null>(() => {
    let uncommonWordsInGroupCount = 0;

    const uncommonWordsInGroup: GroupWordRootFrequency = verses.reduce(
      (acc: GroupWordRootFrequency, entry) => {
        const verseId = typeof entry === "string" ? entry : entry.id;
        if (!verseWRF[verseId]) {
          return acc;
        }

        const verseWordFrequencies = verseWRF[verseId]!;

        for (const { index: wordIndex, root } of verseWordFrequencies) {
          acc[root] ||= [];
          acc[root]!.push({
            verse: verseId,
            wordIndex,
            word: getVerseWord(verseId, wordIndex),
            chapter: getVerseChapterId(verseId),
          });

          uncommonWordsInGroupCount += 1;
        }

        return acc;
      },
      {},
    );

    if (uncommonWordsInGroupCount > 0) {
      return uncommonWordsInGroup;
    } else {
      return null;
    }
  }, [context, verseWRF]);
};
