import { LandingPage } from ".";

export default [
  {
    path: "/",
    queryParams: {
      query: {},
    },
    component: LandingPage,
  },
];
