import { useContext, useEffect, useLayoutEffect } from "react";
import {
  createStateContainerPlugin,
  ProseMirrorContext,
} from "react-prosemirror";
import { MorphTooltipPluginProps } from "../plugin";
import extension from "./extension";

export function ProseMirrorMorph(props: MorphTooltipPluginProps) {
  const { register, view } = useContext(ProseMirrorContext);
  const [propsPlugin, binding] =
    createStateContainerPlugin<MorphTooltipPluginProps>(props);

  useEffect(() => {
    return register({ plugins: [propsPlugin] });
  }, []);

  useEffect(() => {
    return register(extension(binding));
  }, []);

  useLayoutEffect(() => {
    if (view) {
      view.dispatch(view.state.tr.setMeta(binding, props));
    }
  }, [...Object.values(props)]);

  return null;
}
