import type { Node } from "prosemirror-model";
import { NodeSpec } from "prosemirror-model";
import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection,
} from "prosemirror-state";
import { ResolvedPosWithInternalPath } from "../danger";

export const verseCursorPluginKey = new PluginKey("verseCursor");
export const verseCursorPlugin = () =>
  new Plugin({
    key: verseCursorPluginKey,
    state: {
      init: () => null,
      apply(tr) {
        const message = tr.getMeta(verseCursorPluginKey);

        if (message?.scrollTarget) {
          return message.scrollTarget;
        } else {
          return null;
        }
      },
    },

    view: () => ({
      update(view) {
        const scrollTargetPos = verseCursorPluginKey.getState(view.state);

        if (scrollTargetPos) {
          const domNode = view.domAtPos(scrollTargetPos);

          if (domNode) {
            if (domNode.node instanceof HTMLElement) {
              domNode.node.scrollIntoView();
            }
          }
        }
      },
    }),
  });

export const findUnitAfter = (state: EditorState, unitType: NodeSpec) => {
  // TODO: can we stop relying on internals?
  const $anchor = state.selection.$anchor as ResolvedPosWithInternalPath;
  const sourceNodeIndex = $anchor.path.findIndex(
    (x) => (x as Node).type === unitType,
  );

  if (sourceNodeIndex > -1) {
    const nodeAfter = state.doc.resolve(
      Math.min(
        state.doc.nodeSize - 2,
        ($anchor.path[sourceNodeIndex - 1] as number) +
          ($anchor.path[sourceNodeIndex] as Node).nodeSize +
          1,
      ),
    );

    if (nodeAfter && nodeAfter.parent.type === unitType) {
      return nodeAfter.pos;
    }
  }

  return undefined;
};

export const findUnitBefore = (state: EditorState, unitType: NodeSpec) => {
  // TODO: can we stop relying on internals?
  const $anchor = state.selection.$anchor as ResolvedPosWithInternalPath;
  const sourceNodeIndex = $anchor.path.findIndex(
    (x) => (x as Node).type === unitType,
  );

  if (sourceNodeIndex > -1) {
    const nodeBefore = state.doc.resolve(
      Math.max(0, ($anchor.path[sourceNodeIndex - 1] as number) - 1),
    );

    if (nodeBefore && nodeBefore.parent.type === unitType) {
      return Math.max(0, nodeBefore.pos - nodeBefore.parent.nodeSize + 2);
    }
  }

  return undefined;
};

export const jumpToPos = (state: EditorState, pos: number) => {
  const resolved = state.doc.resolve(pos);

  return state.tr
    .setMeta(verseCursorPluginKey, { scrollTarget: pos })
    .setSelection(new TextSelection(resolved, resolved))
    .scrollIntoView();
};
