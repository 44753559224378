import {
  decodeVerseWordForm,
  describeVerseWord,
  getVerseMorph,
} from "@muddakir/corpus";
import { getVerseWordsBetweenChars } from "@muddakir/quran-db";
import { isVerseText } from "prosemirror-quran-schema";
import {
  EditorState,
  Plugin,
  PluginKey,
  PluginView,
  TextSelection,
} from "prosemirror-state";
import { textOffsetBetween } from "prosemirror-text";
import { EditorView } from "prosemirror-view";
import { ResolvedPosWithInternalPath } from "../danger";

export interface MorphTooltipPluginProps {
  enabled: boolean;
}

export const morphTooltipPlugin = (
  binding: PluginKey<MorphTooltipPluginProps>,
) =>
  new Plugin({
    // key: binding,
    // state: {
    //   init: () => enabled,
    //   apply: (tr, value) => {
    //     const nextValue = tr.getMeta(morphTooltipPluginKey);
    //     if (nextValue !== undefined) {
    //       return nextValue;
    //     } else {
    //       return value;
    //     }
    //   },
    // },

    view(view) {
      return new MorphTooltip(view, binding);
    },
  });

class MorphTooltip implements PluginView {
  dom: HTMLDivElement;

  private binding;

  constructor(view: EditorView, binding: PluginKey<MorphTooltipPluginProps>) {
    this.dom = document.createElement("div");
    this.dom.className = "morph-widget";
    this.dom.dir = "rtl";
    this.binding = binding;

    view.dom.parentNode!.appendChild(this.dom);

    this.update(view, null);
  }

  async update(view: EditorView, lastState: EditorState | null) {
    const state = view.state;
    const selection = state.selection as TextSelection;
    const enabled = this.binding.getState(view.state)!.enabled;

    // Hide the tooltip if the selection is empty
    if (!enabled || !selection.$cursor) {
      this.dom.style.display = "none";
      return;
    }

    // Don't do anything if the document/selection didn't change
    if (lastState) {
      const wasEnabled = this.binding.getState(lastState)!.enabled;

      if (
        wasEnabled === enabled &&
        lastState.doc.eq(state.doc) &&
        lastState.selection.eq(state.selection)
      ) {
        return;
      }
    }

    const morph = await this.getWordSegments(state);

    if (!morph) {
      this.dom.style.display = "none";
      return;
    }

    // Otherwise, reposition it and update its content
    this.dom.style.display = "";
    this.dom.innerHTML = genWidgetHTML(morph);

    const targetBox = view.coordsAtPos(selection.$cursor.pos);
    const offsetBox = this.dom.offsetParent!.getBoundingClientRect();
    const widgetBox = this.dom.getBoundingClientRect();

    this.dom.style.left = `${targetBox.left - widgetBox.width}px`;
    this.dom.style.top = `${targetBox.top - offsetBox.top - widgetBox.height}px`;
  }

  async getWordSegments(state: EditorState) {
    const selection = state.selection as TextSelection;

    if (selection.$cursor) {
      const verseNode =
        (selection.$cursor.parent.type === state.schema.nodes.verse &&
          selection.$cursor.parent) ||
        null;

      if (verseNode) {
        const $cursorWithInternalPath =
          selection.$cursor as ResolvedPosWithInternalPath;
        const posOffset = $cursorWithInternalPath.path[
          $cursorWithInternalPath.path.indexOf(verseNode) - 1
        ]! as number;

        const textOffset = textOffsetBetween(
          verseNode,
          selection.$cursor.pos - posOffset,
          selection.$cursor.pos - posOffset,
          isVerseText,
        );

        if (
          typeof textOffset[0] === "number" &&
          typeof textOffset[1] === "number"
        ) {
          const [word] = getVerseWordsBetweenChars(
            verseNode.attrs.id,
            textOffset as [number, number],
          );

          if (word !== null) {
            return await getVerseMorph(verseNode.attrs.id, word);
          }
        }
      }
    }

    return null;
  }

  destroy() {
    this.dom.remove();
  }
}

function genWidgetHTML(segments: VerseWordMorphRecord[]) {
  const html = [];
  let root;

  for (const segment of segments) {
    const [form, tag, features] = segment;

    if (tag === "DET") {
      continue;
    }

    const { text, root: maybeRoot } = describeVerseWord(form, tag, features);

    root = root || maybeRoot;

    html.push(
      `<tr class="morph-segment" data-tag="${tag}"><td>(${decodeVerseWordForm(form)})</td> <td>${text}</td></tr>`,
    );
  }

  if (root) {
    html.push(`<tr><td>(${root})</td><td>الجذر</td></tr>`);
  }

  return `<table><tbody>${html.join("")}</tbody></table>`;
}
