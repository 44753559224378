import { ErrorBoundary } from "@muddakir/engine";
import { GenericErrorHandler } from "@muddakir/error-messages";
import { isFolder } from "@muddakir/folders";
import { isM10t } from "@muddakir/m10t";
import { parse } from "@muddakir/quran-verse-range";
import { anchor } from "@muddakir/verse-anchors/queryParams";
import { vm, vmc } from "@muddakir/verse-markers/queryParams";
import memoize from "memoize";
import { MushafEditor, QueryParams, ScopeSelection } from ".";

export default [
  {
    queryParams: {
      anchor,
      // m10t,
      vm,
      vmc,
    },

    path: ({ url }: { url: string }) => resilientPath(url),
  },
];

const resilientPath = memoize(path);

function path(url: string) {
  const fragments = url.split("/");

  if (!fragments.length || isFolder(fragments[0]!) || isM10t(fragments[0]!)) {
    return null;
  }
  let roots: string | [string, string] | string[] | undefined = [];
  let scopeType;

  for (const [index, fragment] of fragments.entries()) {
    if (index === 0) {
      [roots, scopeType] = parse(fragment) || [];

      if (!scopeType) {
        return null;
      }
    } else if (!parse(fragment)) {
      return null;
    }
  }

  return {
    component: (params: QueryParams & ScopeSelection) => (
      <ErrorBoundary fallback={GenericErrorHandler}>
        <MushafEditor {...params} />
      </ErrorBoundary>
    ),
    params: {
      path: fragments,
      roots,
      scopeType,
    },
  };
}
