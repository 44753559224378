import { useContext, useEffect } from "react";
import { ProseMirrorContext } from "react-prosemirror";
import extension from "./extension";
export interface ProseMirrorFolderProps {}

export function ProseMirrorFolders(_props: ProseMirrorFolderProps) {
  const { register } = useContext(ProseMirrorContext);

  useEffect(() => {
    return register(extension());
  }, []);

  return null;
}
