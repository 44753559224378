import { MainMenuItem, usePreference } from "@muddakir/engine";
import { collapseSelectionOnMark as collapseSelectionOnMarkPref } from "./preferences";

export default function MainMenuItems({
  onInsert,
  onRemove,
  onClear,
}: {
  onInsert: () => boolean;
  onRemove: () => boolean;
  onClear: () => boolean;
}) {
  const [shouldClearSelectionOnMark, setClearSelectionOnMark] = usePreference(
    collapseSelectionOnMarkPref,
  );

  return (
    <>
      <MainMenuItem
        path={["Edit", "Mark"]}
        accel="m"
      />

      <MainMenuItem
        path={["Edit", "Mark", "Insert mark"]}
        onTrigger={onInsert}
        accel="m"
        keyBinding="1"
      />

      <MainMenuItem
        path={["Edit", "Mark", "Remove mark"]}
        onTrigger={onRemove}
        accel="r"
        keyBinding="2"
      />

      <MainMenuItem
        path={["Edit", "Mark", "Clear all marks"]}
        onTrigger={onClear}
        accel="c"
        keyBinding="0"
      />

      <MainMenuItem
        path={["Edit", "Mark", "Clear selection on mark"]}
        onTrigger={() => {
          setClearSelectionOnMark(!shouldClearSelectionOnMark);
          return true;
        }}
        value={!!shouldClearSelectionOnMark}
        type="checkbox"
      />
    </>
  );
}
