import { ErrorBoundary } from "@muddakir/engine";
import { GenericErrorHandler } from "@muddakir/error-messages";
import { ScopeType } from "@muddakir/quran-verse-range";
import memoize from "memoize";
import { FolderMushaf } from "./editor";
import { isFolder } from "./graph";

export default [
  {
    path: ({ url }: { url: string }) => resilientPath(url),
  },
];

const resilientPath = memoize(path);

function path(url: string) {
  const fragments = url.split("/");

  if (fragments && isFolder(fragments[0]!)) {
    return {
      component: (props: any) => (
        <ErrorBoundary fallback={GenericErrorHandler}>
          <FolderMushaf {...props} />
        </ErrorBoundary>
      ),
      params: {
        path: fragments,
        id: fragments[0],
        scopeType: ScopeType.SINGLE,
      },
    };
  } else {
    return null;
  }
}
