import { Node } from "prosemirror-model";
import { EditorView } from "prosemirror-view";
import { ProseMirrorExtension } from "react-prosemirror";
import schema from "../schema";
import { VerseInFolderEmblem } from "../verseInFolderEmblem";

export default (): ProseMirrorExtension => ({
  schema,
  nodeViews: {
    verseInFolderEmblem(node: Node, view: EditorView, getPos: Function) {
      return new VerseInFolderEmblem(node, view, getPos);
    },
  },
});
