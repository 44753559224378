import {
  GraphContext,
  MainMenuItem,
  PreferencesContext,
} from "@muddakir/engine";
import debounce from "debounce";
import type { AbstractGraph, SerializedGraph } from "graphology-types";
import { get, set } from "idb-keyval";
import React, { ReactNode, useContext, useRef } from "react";

export function IDBPersistence({
  children,
  onReplace,
}: {
  children: ReactNode;
  onReplace: (graph: SerializedGraph<AbstractGraph>) => void;
}) {
  const { graph, graphState } = React.useContext(GraphContext);
  const { preferences, assignPreferences } =
    React.useContext(PreferencesContext);
  const [didLoadPreferences, markPreferencesLoaded] = React.useState(false);
  const [didLoadGraph, markGraphLoaded] = React.useState(false);
  const saveGraphToIDB = React.useCallback(
    debounce(() => {
      set("graph", serialize(graph));
    }, 1000),
    [graph],
  );

  React.useEffect(() => {
    get("graph").then((idbState: SerializedGraph<AbstractGraph>) => {
      if (idbState) {
        onReplace(idbState);
      }

      markGraphLoaded(true);
    });
  }, []);

  React.useEffect(saveGraphToIDB, [graph, graphState]);

  React.useEffect(() => {
    get("preferences").then((value) => {
      if (value) {
        assignPreferences(value);
      }

      markPreferencesLoaded(true);
    });
  }, []);

  React.useEffect(() => {
    if (didLoadPreferences) {
      set("preferences", preferences);
    }
  }, [didLoadPreferences, preferences]);

  if (!didLoadGraph || !didLoadPreferences) {
    return null;
  } else {
    return children;
  }
}

// ref: https://wicg.github.io/file-system-access/#dom-savefilepickeroptions-suggestedname
const FilePickerOptions: OpenFilePickerOptions & { suggestedName: string } = {
  id: "openDB",
  suggestedName: "muddakir.json",
  startIn: "documents",
  multiple: false,
  types: [
    {
      description: "Databases",
      accept: {
        "application/json": [".json"],
      },
    },
  ],
};

// see https://web.dev/file-system-access/
export function FSPersistence({
  onMerge,
  onReplace,
  onClear,
}: {
  onMerge: (graph: SerializedGraph<AbstractGraph>) => void;
  onReplace: (graph: SerializedGraph<AbstractGraph>) => void;
  onClear: () => void;
}) {
  const fileHandle = useRef<FileSystemFileHandle | null>();
  const { graph } = useContext(GraphContext);

  const loaddb = async () => {
    if (!fileHandle.current) {
      fileHandle.current = await get("dbfile");

      if (
        !fileHandle.current ||
        !(await verifyPermission(fileHandle.current))
      ) {
        [fileHandle.current] =
          await window.showOpenFilePicker(FilePickerOptions);

        await set("dbfile", fileHandle.current);
      }
    }

    if (fileHandle.current?.kind === "file") {
      const file = await fileHandle.current.getFile();
      const contents = await file.text();

      onReplace(JSON.parse(contents));

      console.debug(
        "loaded graph from file:",
        graph.getAttribute("version"),
        graph.size,
        graph.order,
      );
    }
  };

  const savedb = async () => {
    if (!fileHandle.current) {
      fileHandle.current = await get("dbfile");

      if (!fileHandle.current) {
        fileHandle.current = await window.showSaveFilePicker(FilePickerOptions);

        await set("dbfile", fileHandle.current);
      }
    }

    if (fileHandle.current.kind === "file") {
      const writableStream = await fileHandle.current.createWritable();
      await writableStream.write(JSON.stringify(serialize(graph)));
      await writableStream.close();
    }
  };

  const closedb = async () => {
    fileHandle.current = null;
    await set("dbfile", null);
    onClear();
  };

  const importFileAndMerge = async () => {
    const [fh] = await window.showOpenFilePicker(FilePickerOptions);

    if (fh?.kind === "file") {
      const file = await fh.getFile();
      const text = await file.text();
      const json = JSON.parse(text);
      onMerge(json);
    }
  };

  const importURLAndMerge = async () => {
    const url = prompt("Enter URL to JSON file:");

    if (url) {
      fetch(url)
        .then((response) => response.json())
        .then((data) => onMerge(data));
    }
  };

  return (
    <>
      <MainMenuItem
        path={["File", "Open"]}
        accel="O"
        keyBinding="Ctrl+O"
        onTrigger={() => {
          loaddb();
          return true;
        }}
      />

      <MainMenuItem
        path={["File", "Save"]}
        accel="S"
        keyBinding="Ctrl+S"
        onTrigger={() => {
          savedb();
          return true;
        }}
      />

      <MainMenuItem
        path={["File", "Close"]}
        onTrigger={() => {
          closedb();
          return true;
        }}
      />

      <MainMenuItem
        path={["File", "Import from File"]}
        onTrigger={() => {
          importFileAndMerge();
          return true;
        }}
      />

      <MainMenuItem
        path={["File", "Import from URL"]}
        onTrigger={() => {
          importURLAndMerge();
          return true;
        }}
      />
    </>
  );
}

async function verifyPermission(fileHandle: FileSystemHandle) {
  const options: FileSystemHandlePermissionDescriptor = { mode: "readwrite" };

  if ((await fileHandle.queryPermission(options)) === "granted") {
    return true;
  }

  if ((await fileHandle.requestPermission(options)) === "granted") {
    return true;
  }

  return false;
}

const serialize = (graph: AbstractGraph): object => {
  return graph.export();
};
