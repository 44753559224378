import { t } from "@muddakir/engine";
import Layout from "@muddakir/layout";
import Nav from "@muddakir/mushaf/nav";
import {
  classify,
  expandVerseRange,
  Unit,
  unitsIn,
  versesBetween,
} from "@muddakir/quran-db";
import {
  expand,
  QuranSelector,
  ScopeType,
  UnitOrVerseId,
} from "@muddakir/quran-verse-range";
import { VerseTree, VerseTreeNode } from "@muddakir/quran-verse-tree";
import { useScribe } from "@muddakir/scribe";
import { Jumper } from "@muddakir/verse-jumping";
import { debounce } from "debounce";
import React, { useEffect, useState } from "react";
import {
  computeRootFrequencies,
  findUncommonWordsInSync,
  GroupWordRootFrequency,
} from ".";
import { useVersesWRF, useVerseWRF } from "./useVerseWRF";

interface Props {
  roots: UnitOrVerseId | UnitOrVerseId[];
  path: QuranSelector[];
  scopeType: ScopeType;
}

export default function WRFMushaf({ roots, path, scopeType }: Props) {
  const { error, node, verseGroupWRF, loading } = useDocumentGenerator({
    roots,
    path,
  });

  const { describe, describeRoot, describeForTitle, describeAmongSiblings } =
    useScribe();

  useEffect(() => {
    if (node) {
      document.title = describeForTitle(node);
    }
  }, [node]);

  if (!node) {
    return <Layout />;
  }

  const genNavUnit = (node: VerseTreeNode) => ({
    text: describeAmongSiblings(node)!,
    href: `#/${node.path.join("/")}/words`,
  });

  const parentNodeRel: { text: string; href: string } = {
    text: describe(node)!,
    href: `#/${node.path.join("/")}`,
  };

  const nextNodeRel = node.next ? genNavUnit(node.next) : null;
  const prevNodeRel = node.prev ? genNavUnit(node.prev) : null;

  return (
    <Layout>
      <Jumper
        nextNode={nextNodeRel}
        prevNode={prevNodeRel}
        parentNode={parentNodeRel}
      />
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <div
          className="mushaf"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 0,
          }}
        >
          <div
            dir="rtl"
            style={{
              flexGrow: 1,
              flexShrink: 0,
            }}
          >
            <h1
              style={{
                fontFamily: "Amiri Quran",
                fontSize: "3rem",
                fontWeight: "normal",
              }}
            >
              الكلمات الحصرية في{" "}
              {node.root === node
                ? describeRoot(node, scopeType)
                : describe(node, 2)}
            </h1>

            {error && (
              <pre dir="ltr">
                <code>{error}</code>
              </pre>
            )}
            {loading && <p>{t("Processing...", `جاري المعالجة...`)}</p>}

            {verseGroupWRF && (
              <table>
                <thead>
                  <tr>
                    <th>الجذر</th>
                    <th>الكلمات</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(verseGroupWRF)
                    .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                    .map(([root, rootVerses]) => {
                      return (
                        <tr key={root}>
                          <td>
                            <a
                              target="_blank"
                              href={`https://tafsir.app/lisan/${root}`}
                            >
                              <strong children={root} />
                            </a>
                          </td>
                          <td>
                            <ul style={{ paddingLeft: 0 }}>
                              {rootVerses.map(
                                ({ chapter, verse, word }, index) => (
                                  <li
                                    key={`${verse}-${word}-${index}`}
                                    style={{
                                      listStyle: "none",
                                    }}
                                  >
                                    {`${word} `}
                                    <a
                                      href={`#/${chapter}?a=${verse}`}
                                      className="chapter-verse-anchor"
                                    >
                                      {verse}
                                    </a>
                                  </li>
                                ),
                              )}
                            </ul>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
          </div>

          <div style={{ flexGrow: 0 }}>
            <Nav
              nextNode={nextNodeRel}
              prevNode={prevNodeRel}
              parentNode={parentNodeRel}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

const useDocumentGenerator = ({
  path,
  roots,
}: {
  roots: UnitOrVerseId | UnitOrVerseId[];
  path: QuranSelector[];
}): {
  node: VerseTreeNode | null;
  verseGroupWRF: GroupWordRootFrequency | null;
  loading: boolean;
} & {
  error: string | null;
} => {
  const [didLoad, reload] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const tree = React.useMemo(() => {
    console.log("computing tree");
    const setErrorAtMostOnce = debounce(setError, 10);

    return new VerseTree((id) => {
      try {
        return expand([id]);
      } catch (e) {
        // console.error(e);
        setErrorAtMostOnce(
          () => `Could not find the specified desired resource "${id}"`,
        );
        return [];
      }
    }, createUnitSiblingComputer());
  }, [roots, didLoad]);

  const node = tree.find(path);
  const verseWRF = useVerseWRF({
    verses: node?.verses || NO_VERSES,
    context: path,
  });

  const verseGroupWRF = useVersesWRF({
    context: path,
    verses: node?.verses || NO_VERSES,
    verseWRF,
  });

  useEffect(() => {
    computeRootFrequencies().then(() => {
      reload(() => true);
    });
  }, []);

  useEffect(() => {
    setError(() => null);
  }, [roots]);

  return { error, node, verseGroupWRF, loading: !didLoad };
};

const createUnitSiblingComputer = (): UnitSiblingComputer => {
  const cache = new Map<string, VerseId[]>();

  return ({ id, path, parent }) => {
    const scheme = classify(id);

    if (!scheme) {
      return [];
    }

    const key = path.slice(0, -1).concat([scheme!]).join("/");

    if (!cache.has(key)) {
      const units: UnitOrVerseId[] =
        scheme === Unit.Verse
          ? parent!.verses
          : unitsIn(scheme)(parent!.verses);

      let withWRF = units.filter((unit) => {
        let verses: VerseId[];

        if (scheme === Unit.Verse) {
          verses = [unit];
        } else {
          verses = expandVerseRange(versesBetween([unit, unit]));
        }

        const [, wordCount] = findUncommonWordsInSync(verses);

        return wordCount > 0;
      });

      cache.set(key, withWRF);
    }

    return cache.get(key)!;
  };
};

const NO_VERSES: VerseId[] = [];
