import invariant from "invariant";
import { MarkSpec } from "prosemirror-model";

type AttrsWithChildren = Record<string, unknown> & { children: JSX.Element[] };

declare const process: {
  env: {
    NODE_ENV: "development" | "production";
  };
};

export function createElement(
  factory: string | ((attrs: AttrsWithChildren) => JSX.Element),
  {
    children,
    ...attrs
  }: { children?: JSX.Element[]; text?: string; marks: MarkSpec[] },
) {
  const normalChildren: JSX.Element[] = arrayOf(children || [])
    .flat(Number.MAX_SAFE_INTEGER)
    .filter((x) => !!x);

  if (typeof factory === "function") {
    return factory({ ...attrs, children: normalChildren });
  } else if (factory === "pm:text") {
    return { type: "text", text: attrs.text, marks: attrs.marks };
  }

  const content = normalChildren.map((child) =>
    typeof child === "string" ? { type: "text", text: child } : child,
  );

  if (process.env.NODE_ENV === "development") {
    invariant(
      factory.startsWith("pm:"),
      `unrecognized intrinsic pm element "${factory}"`,
    );
  }

  const doc: PMAbstractCustomNode = {
    type: camelize(factory.slice(3)),
  } as PMAbstractCustomNode;

  if (attrs) {
    doc.attrs = attrs;
  }

  if (content.length) {
    doc.content = content as unknown as PMAbstractCustomNode;
  }

  return doc;
}

export function Fragment({ children }: { children: JSX.Element[] }) {
  return children;
}

export const scryJSX = (root: JSX.Node): boolean => {
  let check = (el: JSX.Node) => {
    if (!el) {
      return false;
    } else if (Array.isArray(el)) {
      return el.some(check);
    } else {
      return true;
    }
  };

  return check(root);
};

function camelize(x: string) {
  return x
    .split("-")
    .map((fragment, index) => {
      if (index > 0 && fragment.length > 0) {
        return fragment[0]!.toUpperCase() + fragment.slice(1);
      }
      return fragment;
    })
    .join("");
}

function arrayOf(x: unknown) {
  return Array.isArray(x) ? x : [x];
}
