import { vm, vmc } from "@muddakir/verse-markers/queryParams";
import { lazy } from "react";
import { referrerQueryParam } from "./queryParams";

export default [
  {
    name: "embeddings-lookup",
    path: "/:scope/lookup/:book",
    queryParams: {
      referrer: referrerQueryParam,
      vm,
      vmc,
    },
    component: lazy(() => import("./lookup")),
  },
];
