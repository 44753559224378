import { RoutingContext } from "@muddakir/engine";
import { FolderBrowser } from "@muddakir/folders";
import { Panel } from "@muddakir/ui/Panel";
import { useContext } from "react";

export default function FolderSidebar({ id }: { id: string | undefined }) {
  const { navigate } = useContext(RoutingContext);

  const leaveActiveFolder = (folderId: GraphNodeId) => {
    if (id === folderId) {
      navigate("/");
    }
  };

  return (
    <Panel
      items={[
        {
          label: "Folders",
          render: () => (
            <FolderBrowser
              key="folders"
              verseMarkers={[]}
              onRemove={leaveActiveFolder}
            />
          ),
        },
      ]}
    />
  );
}
