import { VerseLinkType } from "@muddakir/verse-anchors";
import { Node } from "prosemirror-model";
import { HasNoVerseText } from "prosemirror-quran-schema";

export const m10tSchema = {
  marks: {
    m10tTargetHighlight: {
      attrs: {
        edge: {} as unknown as string,
      },
      toDOM: () => ["span", { class: "m10t-target-highlight" }],
    },
  },
  nodes: {
    m10tSlot: {
      attrs: {
        className: { default: null },
        edge: {},
        m10tEdit: { default: undefined },
        anchor: { default: undefined },
        fingerprint: {},
      } as unknown as PMAttributes<M10tSlotNodeAttrs>,
      inline: true,
      group: "inline",
      atom: true,
      // toDOM: () => [
      //   "span",
      //   { "class": "pm-node-view-missing", "data-node": "m10tSlot" },
      // ],
    },
    m10tGroupEditor: {
      attrs: {
        group: {},
        className: { default: null } as unknown as PMClassName,
        autoFocus: { default: false } as unknown as boolean,
      },
      inline: true,
      group: "inline",
      selectable: false,
      atom: true,
      // toDOM: () => [
      //   "span",
      //   { "class": "pm-node-view-missing", "data-node": "m10tGroupEditor" },
      // ],
    },
    m10tTargetAnchor: {
      [HasNoVerseText]: true,
      inline: true,
      group: "inline",
      content: "text*",
      // atom: true,
      marks: "",
      toDOM: () => ["span", { class: "m10t-target-anchor" }, 0],
    },
  },
};
export default m10tSchema;

export interface M10tSlotNode extends Node {
  attrs: M10tSlotNodeAttrs;
}

export type M10tSlotNodeAttrs = {
  className: string;
  edge: GraphEdgeId;
  m10tEdit: GraphEdgeId | undefined;
  anchor: VerseLinkType | undefined;
  fingerprint: unknown;
};

// export interface M10tVerseNode extends Node {
//   attrs: M10tVerseNodeAttrs;
// }

// export type M10tVerseNodeAttrs = {
//   anchor?: "hyperlink" | "plaintext";
//   edge: GraphEdgeId;
//   marker: VerseMarker;
//   className?: string;
// };
