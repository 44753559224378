import { describeVerseWord, getVerseMorphData } from "@muddakir/corpus";
import {
  getVerseChapterId,
  getVerseId,
  getVerseIndex,
  getVerseWordCharacterRange,
} from "@muddakir/quran-db";

export enum WordFrequency {
  Common = 0,
  Exclusive = 1,
  ExclusiveToChapter = 2,
}
export type VerseWordRootFrequency = Record<
  VerseId,
  {
    index: VerseWordIndex;
    root: WordRoot;
    rank: WordFrequency;
    charRange: [VerseCharacterIndex, VerseCharacterIndex];
  }[]
>;

export type GroupWordRootFrequency = Record<
  WordRoot,
  {
    verse: VerseId;
    wordIndex: VerseWordIndex;
    word: string;
    chapter: UnitId;
  }[]
>;

// type WordFrequency =
//   | ["exclusive"]
//   | ["exclusiveToUnit", UnitId]
//   | ["common", number];

let wfi: {
  // verseWordRanking: Record<VerseIndex, Record<VerseWordIndex, WordFrequency>>,
  verseWordRoots: Record<VerseIndex, Record<VerseWordIndex, WordRoot>>;
  rootOccurrences: Record<WordRoot, VerseId[]>;
};

export const computeRootFrequencies = async () => {
  if (wfi) {
    return wfi;
  }

  const verseMorphData = await getVerseMorphData();
  const rootOccurrences: Record<WordRoot, VerseId[]> = {};
  const verseWordRoots: Record<
    VerseIndex,
    Record<VerseWordIndex, WordRoot>
  > = {};

  for (const [verseIndex, verseWordMorphData] of verseMorphData.entries()) {
    for (const [wordIndex, word] of verseWordMorphData.entries()) {
      for (const segment of word) {
        const root = describeVerseWord(...segment)?.root;

        if (root) {
          rootOccurrences[root] ||= [];
          rootOccurrences[root]!.push(getVerseId(verseIndex));

          verseWordRoots[verseIndex] ||= {};
          verseWordRoots[verseIndex]![wordIndex] = root;
        }
      }
    }
  }

  // const pairs = Object.entries(rootCounts)
  // pairs.sort((a, b) => a[1].length > b[1].length ? -1 : 1)

  wfi = {
    verseWordRoots,
    rootOccurrences,
  };

  return wfi;
};

export const getWordFrequencyRank = async (
  verseId: VerseId,
  wordIndex: VerseWordIndex,
): Promise<WordFrequency | null> => {
  const { verseWordRoots, rootOccurrences } = await computeRootFrequencies();

  const root = verseWordRoots[getVerseIndex(verseId)]![wordIndex];
  if (root) {
    const occurrences = rootOccurrences[root]!;
    return rankWord(occurrences);
  }

  return null;
};

const rankWord = (occurrences: VerseId[]): WordFrequency => {
  if (occurrences.length === 1) {
    return WordFrequency.Exclusive;
  }

  const chapters = new Set<UnitId>();

  for (const verseId of occurrences) {
    chapters.add(getVerseChapterId(verseId));
  }

  if (chapters.size === 1) {
    return WordFrequency.ExclusiveToChapter;
  }

  return WordFrequency.Common;
};

export const findUncommonWordsIn = async (
  verses: VerseId[],
): Promise<VerseWordRootFrequency> => {
  await computeRootFrequencies();
  return findUncommonWordsInSync(verses)[0];
};

const uncommon: VerseWordRootFrequency = {};

export function findUncommonWordsInSync(
  verses: VerseId[],
): [VerseWordRootFrequency, number] {
  if (!wfi) {
    return [{}, 0];
  }

  let count = 0;

  for (const verseId of verses) {
    const uncommonInVerse = rankVerse(verseId);

    count += uncommonInVerse.length;

    if (uncommonInVerse.length) {
      uncommon[verseId] = uncommonInVerse;
    }
  }

  return [uncommon, count];
}

const rankVerse = (verseId: VerseId) => {
  if (!wfi) {
    return [];
  } else if (uncommon.hasOwnProperty(verseId)) {
    return uncommon[verseId]!;
  }

  const { verseWordRoots, rootOccurrences } = wfi;
  const uncommonInVerse = [];

  for (const [word, root] of Object.entries(
    verseWordRoots[getVerseIndex(verseId)] || {},
  )) {
    const rank = rankWord(rootOccurrences[root]!);

    if (rank !== WordFrequency.Common) {
      const wordIndex = parseInt(word, 10);

      uncommonInVerse.push({
        index: wordIndex,
        root,
        rank,
        charRange: getVerseWordCharacterRange(verseId, wordIndex),
      });
    }
  }

  uncommon[verseId] = uncommonInVerse;

  return uncommonInVerse;
};
