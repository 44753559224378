import { claim, genEdgeKey } from "@muddakir/graph";
import {
  getVerseIndex,
  getVerseNumber,
  getVerseText,
  isVerse,
} from "@muddakir/quran-db";
import { MultiDirectedGraph } from "graphology";
import AbstractGraph from "graphology-types";
import { Folder, FolderAttributes, VerseInFolder } from "./types";

const Folder = claim("f");

export const isFolder = Folder.is;
export const add = (
  graph: MultiDirectedGraph,
  { name, parentId }: FolderAttributes,
) => {
  let folder = graph.findNode(
    (key, attrs) =>
      Folder.is(key) &&
      attrs.name === name &&
      (!parentId || graph.areNeighbors(parentId, key)),
  );

  if (!folder) {
    folder = graph.addNode(Folder.gen(), { name });

    if (parentId) {
      graph.addEdgeWithKey(genEdgeKey(), parentId, folder);
    }
  }

  return folder;
};

export const getFolderName = (graph: MultiDirectedGraph, folder: GraphNodeId) =>
  graph.getNodeAttribute(folder, "name");

export const get = (graph: MultiDirectedGraph, folder: GraphNodeId) => ({
  id: folder,
  name: graph.getNodeAttribute(folder, "name"),
});

export const getFolderDescriptors = (
  graph: AbstractGraph,
): {
  id: string;
  name: string;
}[] => {
  return graph
    .filterNodes((node) => Folder.is(node))
    .map((folder) => ({
      id: folder,
      name: graph.getNodeAttribute(folder, "name"),
    }));
};

export const getAll = (graph: MultiDirectedGraph): Folder[] =>
  graph
    .filterNodes((node) => Folder.is(node))
    .map((folder) => {
      const container = graph.findInEdge(folder, (_edge, _attrs, source) =>
        Folder.is(source),
      );

      return {
        id: folder,
        name: graph.getNodeAttribute(folder, "name")!,
        entries: graph.filterOutEdges(
          folder,
          (_edge, _attrs, _source, target) => isVerse(target),
        ).length,
        inside: container ? graph.source(container) : null,
      };
    })
    .sort((a, b) => (a.name > b.name ? 1 : -1));

export const getVersesInFolder = (
  graph: MultiDirectedGraph,
  folder: GraphNodeId,
): VerseInFolder[] =>
  graph
    .filterOutEdges(folder, (_edge, _attrs, _source, target) => isVerse(target))
    .map((edge) => {
      const verse = graph.target(edge);
      const range = graph.getEdgeAttribute(edge, "range") as [
        VerseCharacterIndex,
        VerseCharacterIndex,
      ];

      return {
        id: verse,
        edge,
        text: getVerseText(verse).slice(...range),
        range,
        number: getVerseNumber(verse),
      };
    })
    .sort((a, b) => (getVerseIndex(a.id) > getVerseIndex(b.id) ? 1 : -1));

export const update = (
  graph: MultiDirectedGraph,
  folder: GraphNodeId,
  { name, parentId: parent }: FolderAttributes,
) => {
  if (graph.getNodeAttribute(folder, "name") !== name) {
    graph.setNodeAttribute(folder, "name", name);
  }

  if (!parent) {
    const edges = graph.filterEdges(
      (_edge, _attrs, source, target) => target === folder && Folder.is(source),
    );

    for (const edge of edges) {
      graph.dropEdge(edge);
    }
  } else if (parent !== folder) {
    const edges = graph.filterEdges(
      (_edge, _attrs, source, target) => target === folder && source === parent,
    );

    if (!edges.length) {
      graph.addEdgeWithKey(genEdgeKey(), parent, folder);
    }
  }
};

export const adjustVerseSliceRangeInFolder = (
  graph: AbstractGraph,
  participation: GraphEdgeId,
  range: [number, number],
) => {
  graph.setEdgeAttribute(participation, "range", range);
};

export const canSafelyRemove = (
  graph: MultiDirectedGraph,
  folder: GraphNodeId,
) => graph.degree(folder) === 0;

export const remove = (graph: MultiDirectedGraph, folder: GraphNodeId) => {
  graph.dropNode(folder);
};

export const addVersesToFolder = (
  graph: MultiDirectedGraph,
  folder: GraphNodeId,
  verseMarkers: VerseMarker[],
) => {
  for (const [verse, ...range] of verseMarkers) {
    if (!graph.hasNode(verse)) {
      graph.addNode(verse);
    }

    graph.addEdgeWithKey(genEdgeKey(), folder, verse, {
      range,
    });
  }
};
