import {
  findChainsAcrossUnit,
  intoChainVerseRange,
  isChain,
} from "@muddakir/chains";
import { GraphContext, PluginContext, usePreference } from "@muddakir/engine";
import { useVersesInFolders } from "@muddakir/folders";
import useNotesInURL, { NotesInURL } from "@muddakir/notes/useNotesInURL";
import {
  Unit,
  classify,
  getVerseIndex,
  getVerses,
  unitsIn,
} from "@muddakir/quran-db";
import { expand } from "@muddakir/quran-verse-range";
import { VerseTree, VerseTreeNode } from "@muddakir/quran-verse-tree";
import { Scribe, useScribe } from "@muddakir/scribe";
import { computeVerseHref } from "@muddakir/verse-anchors";
import { groupVersesBy } from "@muddakir/verse-grouping";
import { groupingUnitPreference as groupingUnitPref } from "@muddakir/verse-grouping/preferences";
import {
  aggregateVerseWRFByGroup,
  useVerseWRF,
} from "@muddakir/verse-wrf/useVerseWRF";
import AbstractGraph from "graphology-types";
import React, { DependencyList, useContext, useMemo, useState } from "react";
import { QueryParams, ScopeSelection } from ".";
import layout, { M10tForwardedProps } from "./compose";

const NO_VERSES: VerseId[] = [];

export const useBaseDocument = (
  {
    anchor,
    path,
    roots,
    scopeType,
    m10tEdit,
  }: QueryParams & ScopeSelection & M10tForwardedProps,
  deps: DependencyList,
): {
  notes: NotesInURL;
  error: string | null;
  node: VerseTreeNode | null;
  doc: object | null;
  scribe: Scribe;
} => {
  const { graph, graphState } = React.useContext(GraphContext);
  const [groupingUnit] = usePreference(groupingUnitPref) as [Unit, any];
  const { enabled: plugins } = useContext(PluginContext);
  const [error, setError] = useState<string | null>(null);
  const notes = useNotesInURL({ graph, graphState });
  const scribe = useScribe();

  const tree = React.useMemo(
    () =>
      new VerseTree((id) => {
        try {
          if (isChain(id)) {
            const range = intoChainVerseRange(graph, id);
            console.log(range);
            return expand([`${range[0]}..${range[1]}`]);
          } else {
            return expand([id]);
          }
        } catch (e) {
          console.error(e);
          setError(
            () => `Could not find the specified desired resource "${id}"`,
          );
          return [];
        }
      }, createUnitSiblingComputer(graph)),
    [JSON.stringify(roots)],
  );

  const groupsAndVerses = useMemo(() => {
    const node = tree?.find(path);

    if (!node) {
      return [];
    }

    const hrefComputer = computeVerseHref({ node });
    const verses = getVerses(node.verses);
    const versesWithHref = verses.map((x) => ({ ...x, ...hrefComputer(x) }));

    let chains: Record<GraphNodeId, [VerseIndex, VerseIndex]>;
    let customBuckets = 0;
    let didFindChain = false;
    return groupVersesBy({
      groupingUnit,
      node,
      versePool: versesWithHref,
      resolveCustomGroup: (id: VerseId) => {
        if (!chains) {
          chains = {};

          for (const chain of graph.filterNodes((name) => isChain(name))) {
            const range = intoChainVerseRange(graph, chain).map((x) =>
              getVerseIndex(x),
            );
            chains[chain] = range as [number, number];
          }
          console.log(chains);
        }

        const verseIndex = getVerseIndex(id);

        for (const [chain, range] of Object.entries(chains)) {
          if (verseIndex >= range[0] && verseIndex <= range[1]) {
            didFindChain = true;
            return chain;
          }
        }

        if (didFindChain) {
          customBuckets += 1;
          didFindChain = false;
        }

        return `part:${customBuckets}`;
      },
    });
  }, [tree, JSON.stringify(path), groupingUnit, graphState]);

  const verseWRF = useVerseWRF({
    context: path,
    verses: tree?.find(path)?.verses || NO_VERSES,
  });

  const versesInFolders = useVersesInFolders({
    graph,
    graphState,
    verses: tree?.find(path)?.verses || [],
  });

  const { node, doc } = React.useMemo(() => {
    const node = tree?.find(path);

    if (!node) {
      setError(
        `Unable to find Quran resource at path: ${JSON.stringify(path)}`,
      );
      return { node: null, doc: null };
    }

    // console.debug("recomputing layout");
    const content = layout(
      {
        anchor,
        graph,
        graphState,
        groupingUnit,
        nodeType: classify(node.id),
        plugins,
        scopeType,
        verseWRF,
        verseGroupWRF: aggregateVerseWRFByGroup({
          verseWRF,
          groupsAndVerses,
        }),
        versesInFolders,
        m10tEdit,
        scribe,
      },
      groupsAndVerses,
    );

    // const doc = schema.nodeFromJSON({
    //   type: "doc",
    //   content,
    // });

    return { node, doc: { type: "doc", content } };
  }, [
    tree,
    JSON.stringify(path),
    graphState,
    groupingUnit,
    groupsAndVerses,
    plugins,
    anchor,
    verseWRF,
    ...deps,
  ]);

  return { error, node, doc, notes, scribe };
};

const createUnitSiblingComputer = (
  graph: AbstractGraph,
): UnitSiblingComputer => {
  const cache = new Map<string, VerseId[]>();

  return ({ id, path, parent }) => {
    let scheme = classify(id);

    if (!scheme) {
      if (isChain(id)) {
        scheme = Unit.Custom;
      } else {
        return [];
      }
    }

    const key = path.slice(0, -1).concat([scheme!]).join("/");

    if (!cache.has(key)) {
      let siblings;

      switch (scheme) {
        case Unit.Verse: {
          siblings = parent!.verses;
          break;
        }

        case Unit.Custom: {
          console.log(parent);
          siblings = [];
          const parentScheme = classify(parent.id);
          if (parentScheme) {
            siblings = findChainsAcrossUnit(graph, parent.id);
          }
          break;
        }

        default: {
          siblings = unitsIn(scheme)(parent!.verses);
        }
      }

      cache.set(key, siblings);
    }

    return cache.get(key)!;
  };
};
