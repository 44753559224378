import { useListURLState, useURLState } from "@muddakir/engine";
import { str as crc32 } from "crc-32";
import { vm as vmQueryParam, vmc as vmcQueryParam } from "./queryParams";

export default function useVerseMarkersInURL(
  contextFactors: unknown[],
): [VerseMarker[], (nextValue: VerseMarker[]) => void] {
  const [value, setValue] = useListURLState<VerseMarker>(vmQueryParam);
  const [vmContext, setContext] = useURLState<string>(vmcQueryParam);

  const context = digest(contextFactors.join(","));
  const setAndRecomputeContext = (nextValue: VerseMarker[]) => {
    setValue(nextValue);
    setContext(context);
  };

  return [context === vmContext ? value : [], setAndRecomputeContext];
}

const digest = (x: string) => crc32(x).toString(16);
