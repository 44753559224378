/** @jsxImportSource pmjsx */

import borderLabelFor from "../borderLabelFor";
import { VerseGroup as VerseGroupType } from "../types";

export function VerseGroup({
  index,
  groupAndVerses,
  children,
}: {
  index: number;
  groupAndVerses: {
    group: VerseGroupType | null;
  };
  children: JSX.Node;
}) {
  const { group } = groupAndVerses;

  if (group === null) {
    return children;
  }

  return (
    <pm:verse-group
      id={group.id}
      // @ts-expect-error
      type={group.type /* TODO: what is this supposed to map to? */}
      className={{
        "verse-group--spaced": true,
        "verse-group--lead": index === 0,
        "verse-group--separated": true,
      }}
    >
      {children}

      {group.href && (
        <pm:verse-group-anchor
          href={group.href!}
          altHref={group.altHref}
        >
          {`${borderLabelFor(group) || group.id}`}
        </pm:verse-group-anchor>
      )}
    </pm:verse-group>
  );
}
